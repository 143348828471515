import React, { useContext, useEffect, useState } from "react";
import { GetResultsMenuItems } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import { resolveIconForSingleRow } from "../../Helpers/Helpers";
import ResultsMenuSingleItem from "./ResultsMenuSingleItem";

const ResultsMenu = (props) => {
  const [menuItems, setMenuItems] = useState(null);

  const consumer = useContext(GeneralContext);

  useEffect(() => {
    let x = new Date();
    const yy = x.getFullYear();
    let mm = x.getMonth() + 1;
    let dd = x.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    let date = dd + "" + mm + "" + yy.toString().substr(-2);

    GetResultsMenuItems(date, setMenuItems);
  }, []);

  useEffect(() => {
    let xx = consumer.dateSelectorRes[0].indexOf(1);
    let dd = new Date();
    dd.setDate(dd.getDate() - xx);

    const yy = dd.getFullYear();
    let mm = dd.getMonth() + 1;
    let ddd = dd.getDate();

    if (ddd < 10) ddd = "0" + ddd;
    if (mm < 10) mm = "0" + mm;

    let final = ddd + "" + mm + "" + yy.toString().substr(-2);
    GetResultsMenuItems(final, setMenuItems);
  }, [consumer.dateSelectorRes[0]]);

  return (
    menuItems && (
      <div className="results_menu_root">
        <div className="results_menu_menu">
          <ResultsMenuSingleItem
            title="TOATE SPORTURILE"
            icon="./icons/all.png"
            id={0}
            setActiveItem={consumer.activeResultsMenuItem[1]}
            activeItem={consumer.activeResultsMenuItem[0]}
            setActiveName={consumer.activeResultsName[1]}
            handleMobileMenuOpen={props.handleMobileMenuOpen}
          />
          {menuItems.map((element, i) => {
            return (
              <div key={i}>
                <ResultsMenuSingleItem
                  title={element.name}
                  icon={resolveIconForSingleRow(element.id)}
                  evNo={element.eventsCount}
                  id={element.id}
                  setActiveItem={consumer.activeResultsMenuItem[1]}
                  activeItem={consumer.activeResultsMenuItem[0]}
                  setActiveName={consumer.activeResultsName[1]}
                  handleMobileMenuOpen={props.handleMobileMenuOpen}
                />
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default ResultsMenu;
