import React, { useState } from "react";
import { sendInfo } from "../../API/ApiManager";
import "./Contact.css";

const Contact = () => {
  const [nume, setNume] = useState("");
  const [mail, setMail] = useState("");
  const [mesaj, setMesaj] = useState("");

  const changeName = (e) => setNume(e.target.value);
  const changeMail = (e) => setMail(e.target.value);
  const changeMesaj = (e) => setMesaj(e.target.value);

  return (
    <div className="contact_root">
      <div className="contact_wrap">
        <div className="contact_header">CONTACT</div>
        <div className="contact_content">
          <div className="contact_left">
            <div className="left_inp">
              <label>Nume: </label>
              <input type="text" value={nume} onChange={(e) => changeName(e)} />
            </div>
            <div className="left_inp">
              <label>Email: </label>
              <input type="text" value={mail} onChange={(e) => changeMail(e)} />
            </div>
            <div className="left_inp">
              <label>Mesaj: </label>
              <textarea
                rows={8}
                cols={30}
                value={mesaj}
                onChange={(e) => changeMesaj(e)}
              ></textarea>
            </div>

            <div
              className="contact_left_button"
              onClick={() => {
                if (nume != "" && mail != "" && mesaj != "") {
                  sendInfo(nume, mail, mesaj);
                } else {
                  alert("Toate campurile sunt obligatorii");
                }
              }}
            >
              Trimite
            </div>
          </div>
          <div className="contact_right">
            <div>Telefon: 0771 016 287</div>
            <div>Email: cloverbet18@gmail.com</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
