import React, { useEffect } from "react";
import {
  BrowserRouter,
  BrowserRouter as Router,
  HashRouter,
  Switch,
  useLocation,
} from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navigation from "./Components/Navigation/Navigation";

const Layout = (props) => {
  const loc = useLocation();

  return (
    <div className="layout_root">
      <div className="layout_wrap">
        <HashRouter>
          {loc.hash != "#/presentation" ? (
            <div className="layout_header">
              <Navigation />
            </div>
          ) : null}
          <div className="layout_content">
            <Switch>{props.children}</Switch>
          </div>
        </HashRouter>
      </div>
      {loc.hash != "#/presentation" ? <Footer /> : null}
    </div>
  );
};

export default Layout;
