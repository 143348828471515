import React, { useContext, useState } from "react";
import { VerifyTicket } from "../../API/ApiManager";
import { GeneralContext } from "../../App";

const MobileVerification = () => {
  const consumer = useContext(GeneralContext);

  const [verifSerial, setVerifSerial] = useState(null);
  const handleSerialChange = (e) => setVerifSerial(e.target.value);
  return (
    <>
      <h5 className="verify_root_mobile_lbl">Verificare ticket</h5>
      <div className="mobile_verif_root">
        <input
          type="text"
          placeholder="Cod bilet"
          className="ticket_verif_input"
          onChange={(e) => handleSerialChange(e)}
        />
        <div
          className="ticket_verif_button"
          onClick={() => VerifyTicket(verifSerial, consumer.verifyResponse[1])}
        >
          &#62;
        </div>
      </div>
    </>
  );
};

export default MobileVerification;
