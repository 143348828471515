import React from "react";

const ResultsMenuSingleItem = (props) => {
  const resolveClassName = (id) => {
    if (id === props.activeItem)
      return "menu_single_item_root spmenu_is_active";
    return "menu_single_item_root";
  };

  return (
    <div
      className={resolveClassName(props.id)}
      onClick={() => {
        props.setActiveItem(props.id);
        props.setActiveName(props.title);
        props.handleMobileMenuOpen && props.handleMobileMenuOpen();
      }}
    >
      <div className="menu_single_item_icon">
        <img src={props.icon} className="menu_single_item_icon_icon" />
      </div>
      <div className="menu_single_item_name">{props.title}</div>
      <div className="menu_single_item_events">{props.evNo}</div>
    </div>
  );
};

export default ResultsMenuSingleItem;
