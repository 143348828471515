import { Modal } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Barcode from "react-barcode/lib/react-barcode";
import { GeneralContext } from "../App";
import TicketLine from "../Pages/FrontPage/TicketLine";
import TicketLineV2 from "../Pages/Sport/TicketLineV2";
import "./Modal.css";

const ModalVerif = (props) => {
  const consumer = useContext(GeneralContext);

  const resolveImgDesc = () => {
    if (consumer.verifyResponse[0].ticketStatus === "WIN") return "./win.png";
    else if (consumer.verifyResponse[0].ticketStatus === "LOST")
      return "./lost.png";
    else return "./pnd.png";
  };

  const resolveImgDescLabel = () => {
    if (consumer.verifyResponse[0].ticketStatus === "WIN")
      return "BILET CASTIGATOR";
    else if (consumer.verifyResponse[0].ticketStatus === "LOST")
      return "BILET PIERZATOR";
    else return "BILET ACTIV";
  };

  return (
    consumer.verifyResponse[0] && (
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="modal_ticket_root"
      >
        <div className="modal_ticket_container">
          <div className="modal_ticket_header">
            <div className="modal_ticket_titles">
              <div className="modal_ticket_sport">
                SPORT |{" "}
                {consumer.verifyResponse[0].noOfCombinations === 1
                  ? "SIMPLU"
                  : "SYSTEM"}
              </div>
              <div
                className="modal_ticket_close"
                onClick={() => {
                  props.onClose();
                  consumer.verifyResponse[1](null);
                }}
              >
                X
              </div>
            </div>
          </div>
          <div className="modal_ticket_events">
            <div className="modal_ticket_event">
              Evenimente:{" "}
              <span>{consumer.verifyResponse[0].ticketLines.length}</span>
            </div>
            <div className="modal_ticket_totalodd">
              Cota totala: <span>{consumer.verifyResponse[0].totalQuote}</span>
            </div>
          </div>
          <div className="modal_ticket_content">
            <div className="modal_ticket_left">
              {consumer.verifyResponse[0].ticketLines.map((el, key) => {
                return (
                  <>
                    <br />
                    <TicketLineV2
                      event={el}
                      stake={consumer.spStake[0]}
                      setStake={consumer.spStake[1]}
                      key={key}
                      for="modal"
                    />
                    <br />
                  </>
                );
              })}
            </div>
            <div className="modal_ticket_right">
              <div className="modal_ticket_icondesc">
                <img src={resolveImgDesc()} width="81px" heigth="81px" />
              </div>
              <div className="modal_ticket_icon_label">
                {resolveImgDescLabel()}
              </div>
              <div className="modal_ticket_barcode_verif">
                <Barcode value={consumer.verifyResponse[0].ticketSerial} />
              </div>
              <div className="modal_ticket_informations">
                <div className="modal_ticket_total_odd">
                  Cota totala:{" "}
                  <span>{consumer.verifyResponse[0].totalQuote}</span>
                </div>
                <div className="modal_ticket_system_inf">
                  System:{" "}
                  <span>
                    {consumer.verifyResponse[0].systemPlayed === ""
                      ? "Se castiga cu " +
                        consumer.verifyResponse[0].ticketLines.length +
                        "/" +
                        consumer.verifyResponse[0].ticketLines.length
                      : consumer.verifyResponse[0].systemPlayed}
                  </span>
                </div>
                <div className="modal_ticket_miza_inf">
                  Miza:{" "}
                  <span>{consumer.verifyResponse[0].playedAmount} RON</span>
                </div>

                <div className="modal_ticket_tax_cda">
                  Tax CDA:
                  <span>{consumer.verifyResponse[0].taxAmount} RON</span>
                </div>
                <div className="modal_ticket_miza_after">
                  Miza dupa aplicarea taxei:{" "}
                  <span>{consumer.verifyResponse[0].stakeAmount} RON</span>
                </div>
                <div className="modal_ticket_min_win">
                  Casting potential min.{" "}
                  <span>
                    {consumer.verifyResponse[0].possibleWinningMin} RON
                  </span>
                </div>
              </div>
              <div className="modal_ticket_buttons">
                <div className="modal_ticket_max_win">
                  Castig potential:{" "}
                  <span>
                    {consumer.verifyResponse[0].possibleWinningMax} RON
                  </span>
                </div>
                <div className="modal_ticket_btns">
                  <div
                    className="modal_ticket_btn_close"
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    INCHIDE
                  </div>
                  {/* <div
                    className="modal_ticket_btn_print"
                    onClick={() => alert("Buna ziua cuaiee")}
                  >
                    PRINTARE
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default ModalVerif;
