import React, { useContext, useEffect, useState } from "react";
import { GetOfferBySport } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import SubMenuItems from "./SubMenuItems";

const MenuSingleItem = (props) => {
  const consumer = useContext(GeneralContext);

  const [subMenuItems, setSubMenuItems] = useState(null);

  const resolveClassName = (id) => {
    if (id === props.activeItem.id)
      return "menu_single_item_root spmenu_is_active";
    return "menu_single_item_root";
  };

  useEffect(() => {
    if (consumer.activeMenuItemSport[0].id != -1)
      GetOfferBySport(
        consumer.activeMenuItemSport[0].id,
        consumer.dateSelector[0].indexOf(1),
        setSubMenuItems
      );
  }, []);

  return (
    <>
      <div
        className={resolveClassName(props.id)}
        onClick={() => {
          props.setActiveItem({ id: props.id, name: props.title });
          GetOfferBySport(
            props.id,
            consumer.dateSelector[0].indexOf(1),
            setSubMenuItems
          );
          if (props.id === -1) props.handleMobileMenuOpen();
        }}
      >
        <div className="menu_single_item_icon">
          <img src={props.icon} className="menu_single_item_icon_icon" />
        </div>
        <div className="menu_single_item_name">{props.title}</div>
        <div className="menu_single_item_events">{props.evNo}</div>
      </div>
      {subMenuItems && props.id === props.activeItem.id && props.id != -1 ? (
        <SubMenuItems
          items={subMenuItems}
          handleMobileMenuOpen={props.handleMobileMenuOpen}
        />
      ) : null}
    </>
  );
};

export default MenuSingleItem;
