import React, { useEffect, useState } from "react";
import { GetOfferBySport } from "../../API/ApiManager";
import { resolveIconForSingleRow } from "../../Helpers/Helpers";
import LeagueBigCard from "./LeagueBigCard";

const LandingContent = (props) => {
  return (
    props.events && (
      <div className="landing_page_root">
        <div className="landing_page_banner">
          <img src="./clover_banner.jpg" className="landing_page_banner_img" />
        </div>

        <div className="landing_page_label">
          <span>TOP 20 CELE MAI PARIATE EVENIMENTE</span>
        </div>
        <div className="landing_page_events">
          {props.events.map((elem, key) => {
            return (
              <div key={key}>
                <LeagueBigCard
                  icon={resolveIconForSingleRow(elem.sportID)}
                  title={elem.leagueName}
                  label={elem.sportName}
                  events={elem.Events}
                />
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

export default LandingContent;
