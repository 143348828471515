import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "../../App";
import { getCurrentDaysArr } from "../../Helpers/Helpers";
import DateSelector from "../Sport/DateSelector";
import "./Loto.css";
import LotoContent from "./LotoContent";
import LotoMenu from "./LotoMenu";
import TicketLoto from "./TicketLoto";

const Loto = () => {
  const consumer = useContext(GeneralContext);
  const [shortDays, setShortDays] = useState(null);

  useEffect(() => {
    setShortDays(getCurrentDaysArr("RO-ro"));
  }, []);

  const formatDateSelector = (index) => {
    return (
      shortDays && (
        <span style={{ fontWeight: 600 }}>
          {shortDays.shortDays[index]}{" "}
          <span style={{ fontWeight: 300 }}>
            {shortDays.dayNmonth[index].day +
              "." +
              shortDays.dayNmonth[index].month}
          </span>
        </span>
      )
    );
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMobileMenuOpen = () => {
    setDateMenuOpen(false);
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  const handleDateMenuOpen = () => {
    setMobileMenuOpen(false);
    setDateMenuOpen(!dateMenuOpen);
  };
  const [mobileTicketOpen, setMobileTicketOpen] = useState(false);
  const handleMobileTicketOpen = () => setMobileTicketOpen(!mobileTicketOpen);

  const classNameTicketResolver = (xx) => {
    if (xx === true) return "mobile_ticket_loto_opened mobile_tick_show";
    return "mobile_ticket_loto_opened mobile_tick_hide";
  };
  return (
    <>
      <div className="rooooot">
        <div className="loto_root">
          <div className="loto_menu">
            <LotoMenu />
          </div>
          <div className="loto_content">
            <LotoContent />
          </div>
          <div className="loto_ticket">
            <TicketLoto />
          </div>
        </div>
      </div>
      <div className="mobile_loto_root">
        <div className="mobile_sport_selectors">
          <div
            className="mobile_sport_selected_sport"
            onClick={() => handleMobileMenuOpen()}
          >
            {consumer.lotoActiveItem[0].name}
          </div>
          <div
            className="mobile_sport_selected_date"
            onClick={() => handleDateMenuOpen()}
          >
            {consumer.dateSelector[0].indexOf(1) === 0
              ? "Astazi"
              : formatDateSelector(consumer.dateSelector[0].indexOf(1))}
          </div>
        </div>

        <div className="mobile_loto_content">
          <LotoContent />
        </div>

        <div
          className="mobile_frontpage_ticket"
          onClick={(e) => {
            e.stopPropagation();
            handleMobileTicketOpen();
          }}
        >
          <div className="mobile_frontpage_ticket_events">
            {consumer.ltStake[0] != null ? consumer.ltStake[0].length : "0"}
          </div>
          <div className="mobile_frontpage_ticket_rows">
            <div className="mobile_fp_ticket_row_1">
              <div className="mobile_frontpage_ticket_label1">Variante</div>
              <div className="mobile_frontpage_ticket_label2">Miza</div>
              <div className="mobile_frontpagE_ticket_label3">
                Castig potential
              </div>
            </div>
            <div className="mobile_fp_ticket_row_2">
              <div className="mobile_frontpage_ticket_label1">
                {consumer.lotoEvaluate[0] != null
                  ? consumer.lotoEvaluate[0].combinations
                  : "0"}
              </div>
              <div className="mobile_frontpage_ticket_label2">
                {consumer.lotoTicketStake[0]} <span>RON</span>
              </div>
              <div className="mobile_frontpagE_ticket_label3">
                {consumer.lotoEvaluate[0] != null
                  ? consumer.lotoEvaluate[0].maxWin
                  : "0"}
                <span>RON</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {mobileMenuOpen && (
        <div className="mobile_frontpage_menu_mask">
          <LotoMenu handleMobileMenuOpen={handleMobileMenuOpen} />
        </div>
      )}
      {dateMenuOpen && (
        <div className="mobile_frontpage_menu_mask">
          <DateSelector handleDateMenuOpen={handleDateMenuOpen} />
        </div>
      )}

      <div className={classNameTicketResolver(mobileTicketOpen)}>
        <TicketLoto handleMobileTicketOpen={handleMobileTicketOpen} />
      </div>
    </>
  );
};

export default Loto;
