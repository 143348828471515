import React from "react";
import EventSmallCard from "./EventSmallCard";

const LeagueBigCard = (props) => {
  return (
    <div className="league_bigcard_root">
      <div className="league_bigcard_header">
        <div className="league_bigcard_header_icon">
          <img src={props.icon} width="24px" height="24px" />
        </div>
        <div className="league_bigcard_header_name">{props.title}</div>
      </div>
      <div className="league_bigcard_label">
        <div className="league_bigcard_label_lbl">{props.label}</div>
        <div className="league_bigcard_odd_desc">1</div>
        <div className="league_bigcard_odd_desc">X</div>
        <div className="league_bigcard_odd_desc">2</div>
        <div className="league_bigcard_odd_desc">1X</div>
        <div className="league_bigcard_odd_desc">X2</div>
        <div className="league_bigcard_odd_desc">12</div>
      </div>
      <div className="league_bigcard_events">
        {props.events.map((elem, key) => {
          return (
            <div key={key} className="league_bigcard_small_wrap">
              <EventSmallCard event={elem} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LeagueBigCard;
