import { Alert } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GetAllOddsForEvent, GetOfferBySport } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import {
  AddToSportStake,
  checkIfOddExistsInStake,
  RemoveFromTicket,
} from "../../Helpers/Helpers";

const EventCard = (props) => {
  const consumer = useContext(GeneralContext);

  const resolveOddClassName = (stake, matchId, oddId) => {
    if (stake != null) {
      // let xxx = stake.eventsArray.split(",");
      let aux = [];

      if (!consumer.ticketContent[0]) {
        aux = stake.eventsArray.split(",");
      } else {
        for (let i = 0; i < consumer.ticketContent[0].length; i++) {
          aux.push(consumer.ticketContent[0][i].eventID);
        }
      }

      let aux2 = [];
      if (!consumer.ticketContent[0]) {
        aux2 = stake.codesArray.split(",");
      } else {
        for (let i = 0; i < consumer.ticketContent[0].length; i++) {
          aux2.push(consumer.ticketContent[0][i].typeCode);
        }
      }
      let idx = aux.indexOf(matchId);

      if (idx != -1) {
        if (aux2[idx] === oddId) {
          return "event_small_card_wrap_selected";
        } else return "event_small_card_wrap";
      } else return "event_small_card_wrap";
    } else return "event_small_card_wrap";
  };

  const getCurrentCorrectOddValue = (symbol) => {
    let flag = null;

    props.event.Odds.map((elem, i) => {
      if (elem.symbol.toString() === symbol.toString()) {
        flag = elem.value;
      }
    });
    if (flag != null && flag != 0) return flag;
    else return "-";
  };

  const getCurrentCorrectOddId = (symbol) => {
    let flag = null;

    props.event.Odds.map((elem, i) => {
      if (elem.symbol.toString() === symbol.toString()) {
        flag = elem.id;
      }
    });
    if (flag != null) return flag;
    else return "-";
  };

  const onClickOdd = (value, id) => {
    if (value != "-" && value != null && value != 0) {
      if (
        !checkIfOddExistsInStake(consumer.spStake[0], props.event.matchID, id)
      ) {
        AddToSportStake(
          consumer.spStake[0],
          consumer.spStake[1],
          consumer.combArray[0].join(","),
          consumer.serial[0],
          consumer.ticketStake[0],
          props.event.matchID,
          id,
          ""
        );
      } else {
        RemoveFromTicket(
          consumer.spStake[0],
          consumer.spStake[1],
          props.event.matchID
        );
      }
    }
  };

  const [allOddsWrap, setAllOddsWrap] = useState(null);

  const [allOddsRng, setAllOddsRng] = useState(null);

  const getRandomOdd = (array) => {
    let allOdds = [];

    array.map((elem, key) => {
      if (key > 0) {
        elem.odds.map((el, i) => {
          allOdds.push(el);
        });
      }
    });

    setAllOddsRng(allOdds);
  };

  useEffect(() => {
    if (allOddsWrap != null) {
      getRandomOdd(allOddsWrap);
    }
  }, [allOddsWrap]);

  useEffect(() => {
    if (allOddsRng != null) {
      let xxx = allOddsRng.length;
      let rng = Math.floor(Math.random() * xxx);

      let selectedObj = allOddsRng[rng];

      if (selectedObj.value > 1) {
        AddToSportStake(
          consumer.spStake[0],
          consumer.spStake[1],
          consumer.combArray[0].join(","),
          consumer.serial[0],
          consumer.ticketStake[0],
          props.event.matchID,
          selectedObj.id,
          ""
        );
      }
    }
  }, [allOddsRng]);

  return (
    <div className="event_small_card_root">
      <div className="event_small_card_details">
        <div>
          <div className="event_small_details_match">
            {props.event.homeTeam} VS {props.event.awayTeam}
          </div>
          <div className="event_small_details_date">
            {props.event.abbrDate} {props.event.abbrHour}
          </div>
        </div>
        <div className="event_small_details_icons2">
          <div
            className="event_small_card_plus"
            onClick={() =>
              GetAllOddsForEvent(
                props.event.matchID,
                consumer.allOddsForEvent[1]
              )
            }
          >
            +{props.event.MarketsCount}
          </div>
          {/* <div
            className="event_small_card_random"
            onClick={() => {
              GetAllOddsForEvent(props.event.matchID, setAllOddsWrap);
            }}
          >
            <img src="./random.png" className="event_small_card_random_icon" />
          </div> */}
        </div>
        <div className="event_small_details_icons">
          <div
            className="event_small_card_plus"
            onClick={() =>
              GetAllOddsForEvent(
                props.event.matchID,
                consumer.allOddsForEvent[1]
              )
            }
          >
            +{props.event.MarketsCount}
          </div>
          {/* <div
            className="event_small_card_random"
            onClick={() => {
              GetAllOddsForEvent(props.event.matchID, setAllOddsWrap);
            }}
          >
            <img src="./random.png" className="event_small_card_random_icon" />
          </div> */}
        </div>
      </div>
      <div className="event_small_card_odds_wrap">
        <div className="event_small_card_odd">
          <div
            className={resolveOddClassName(
              consumer.spStake[0],
              props.event.matchID,
              getCurrentCorrectOddId("1")
            )}
            onClick={() =>
              onClickOdd(
                getCurrentCorrectOddValue("1"),
                getCurrentCorrectOddId("1")
              )
            }
          >
            <span className="mobile_to_hide_sport">1</span>
            {getCurrentCorrectOddValue("1")}
          </div>
        </div>
        <div className="event_small_card_odd">
          <div
            className={resolveOddClassName(
              consumer.spStake[0],
              props.event.matchID,
              getCurrentCorrectOddId("X")
            )}
            onClick={() =>
              onClickOdd(
                getCurrentCorrectOddValue("X"),
                getCurrentCorrectOddId("X")
              )
            }
          >
            <span className="mobile_to_hide_sport">X</span>
            {getCurrentCorrectOddValue("X")}
          </div>
        </div>
        <div className="event_small_card_odd">
          <div
            className={resolveOddClassName(
              consumer.spStake[0],
              props.event.matchID,
              getCurrentCorrectOddId("2")
            )}
            onClick={() =>
              onClickOdd(
                getCurrentCorrectOddValue("2"),
                getCurrentCorrectOddId("2")
              )
            }
          >
            <span className="mobile_to_hide_sport">2</span>
            {getCurrentCorrectOddValue("2")}
          </div>
        </div>
        <div className="event_small_card_odd">
          <div
            className={resolveOddClassName(
              consumer.spStake[0],
              props.event.matchID,
              getCurrentCorrectOddId("1X")
            )}
            onClick={() =>
              onClickOdd(
                getCurrentCorrectOddValue("1X"),
                getCurrentCorrectOddId("1X")
              )
            }
          >
            <span className="mobile_to_hide_sport">1X</span>
            {getCurrentCorrectOddValue("1X")}
          </div>
        </div>
        <div className="event_small_card_odd">
          <div
            className={resolveOddClassName(
              consumer.spStake[0],
              props.event.matchID,
              getCurrentCorrectOddId("X2")
            )}
            onClick={() =>
              onClickOdd(
                getCurrentCorrectOddValue("X2"),
                getCurrentCorrectOddId("X2")
              )
            }
          >
            <span className="mobile_to_hide_sport">X2</span>
            {getCurrentCorrectOddValue("X2")}
          </div>
        </div>
        <div className="event_small_card_odd">
          <div
            className={resolveOddClassName(
              consumer.spStake[0],
              props.event.matchID,
              getCurrentCorrectOddId("12")
            )}
            onClick={() =>
              onClickOdd(
                getCurrentCorrectOddValue("12"),
                getCurrentCorrectOddId("12")
              )
            }
          >
            <span className="mobile_to_hide_sport">12</span>
            {getCurrentCorrectOddValue("12")}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
