export const resolveIconForSingleRow = (id) => {
  if (id === 85) return "./icons/clover.png";
  if (id === 86) return "./icons/announcement.png";
  if (id === 2) return "./icons/footbal.png";
  if (id === 4) return "./icons/tenis.png";
  if (id === 3) return "./icons/basket.png";
  if (id === 6) return "./icons/handball.png";
  if (id === 5) return "./icons/hokey.png";
  if (id === 10) return "./icons/volley.png";
  if (id === 8) return "./icons/pool.png";
  if (id === 18) return "./icons/baseball.png";
  return "./icons/clover.png";
};

export const AddToSportStake = (
  stake,
  setStake,
  system,
  serial,
  amount,
  eventsArr,
  codeArr,
  fixedArr
) => {
  if (stake === null) {
    let obj = {
      ticketSystem: system,
      ticketSerial: serial,
      ticketAmount: amount,
      eventsArray: eventsArr + ",",
      codesArray: codeArr + ",",
      fixedArray: fixedArr,
    };
    setStake(obj);
  } else {
    if (checkIfEventExistsInStake(stake, eventsArr)) {
      let xxx = stake.eventsArray;
      let xxxArr = xxx.split(",");
      let idOf = xxxArr.indexOf(eventsArr.toString());

      let yyy = stake.codesArray;
      let yyyArr = yyy.split(",");
      yyyArr[idOf] = codeArr;
      let finalyyy = yyyArr.join(",");

      let obj = {
        ticketSystem: system,
        ticketSerial: serial,
        ticketAmount: amount,
        eventsArray: stake.eventsArray,
        codesArray: finalyyy,
        fixedArray: fixedArr,
      };
      setStake(obj);
    } else {
      let obj = {
        ticketSystem: system,
        ticketSerial: serial,
        ticketAmount: amount,
        eventsArray: stake.eventsArray + eventsArr + ",",
        codesArray: stake.codesArray + codeArr + ",",
        fixedArray: fixedArr,
      };

      setStake(obj);
    }
  }
};

const checkIfEventExistsInStake = (stake, matchId) => {
  let xxx = stake.eventsArray;
  let xxxArr = xxx.split(",");

  if (xxxArr.indexOf(matchId.toString()) != -1) return true;
  else return false;
};

export const RemoveFromTicket = (stake, setStake, matchId) => {
  let xxx = stake.eventsArray.split(",");
  let idx = xxx.indexOf(matchId.toString());
  let yyy = stake.codesArray.split(",");
  if (idx != -1) {
    xxx.splice(idx, 1);
    yyy.splice(idx, 1);
  }
  let obj = {
    ticketSystem: stake.ticketSystem,
    ticketSerial: stake.ticketSerial,
    ticketAmount: stake.ticketAmount,
    eventsArray: xxx.join(","),
    codesArray: yyy.join(","),
    fixedArray: stake.fixedArray,
  };

  setStake(obj);
};

export const getCurrentDaysArr = (language) => {
  const d = new Date();
  let shortDaysArr = [];
  let dayNmonth = [];
  for (let i = 0; i < 8; i++) {
    let objShort =
      d.toLocaleString(language, { weekday: "short" }).charAt(0).toUpperCase() +
      d.toLocaleString(language, { weekday: "short" }).slice(1);
    // if (objShort.length > 3) objShort = objShort.slice(0, -1);
    shortDaysArr.push(objShort);
    let obj = {
      day: d.getDate() > 9 ? d.getDate() : "0" + d.getDate(),
      month: d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1),
    };
    d.setDate(d.getDate() + 1);
    dayNmonth.push(obj);
  }
  let toReturn = {
    shortDays: shortDaysArr,
    dayNmonth: dayNmonth,
  };
  return toReturn;
};

export const checkIfOddExistsInStake = (stake, matchId, oddId) => {
  if (stake) {
    let xxx = stake.eventsArray;
    let yyy = stake.codesArray;

    let xxxArr = xxx.split(",");
    let idx = xxxArr.indexOf(matchId.toString());
    let yyyArr = yyy.split(",");

    if (idx != -1) {
      if (yyyArr[idx] === oddId.toString()) return true;
    }

    return false;
  }
  return false;
};
export const convertUtcToSingleRowTime = (utcString, culture) => {
  let ddd = new Date(utcString);
  let day;
  if (culture === "en") {
    day =
      ddd
        .toLocaleString("EN-en", { weekday: "short" })
        .charAt(0)
        .toUpperCase() +
      ddd.toLocaleString("EN-en", { weekday: "short" }).slice(1);
  } else if (culture === "fr") {
    day =
      ddd
        .toLocaleString("FR-fr", { weekday: "short" })
        .charAt(0)
        .toUpperCase() +
      ddd.toLocaleString("FR-fr", { weekday: "short" }).slice(1);
  } else if (culture === "ro") {
    day =
      ddd
        .toLocaleString("RO-ro", { weekday: "short" })
        .charAt(0)
        .toUpperCase() +
      ddd.toLocaleString("RO-ro", { weekday: "short" }).slice(1);
  }
  let hour = ddd.getHours() > 9 ? ddd.getHours() : "0" + ddd.getHours();
  let mins = ddd.getMinutes() > 9 ? ddd.getMinutes() : "0" + ddd.getMinutes();
  return day + " " + hour + ":" + mins;
};

export const AddToLotoStake = (stake, setStake, number, maxBet) => {
  let auxStake = [];
  let isAlreadyInStake = false;
  let indexOfFound = -1;
  if (stake.length > 0) {
    for (let i = 0; i < stake.length; i++) {
      if (stake[i] === number) {
        isAlreadyInStake = true;
        indexOfFound = i;
      }
    }
  }

  if (isAlreadyInStake === false) {
    if (stake.length < maxBet) {
      auxStake = [...stake];
      auxStake.push(number);
      setStake(auxStake);
    }
  } else if (isAlreadyInStake === true) {
    let auxMF = [...stake];
    auxMF.splice(indexOfFound, 1);
    setStake(auxMF);
  }
};
export const getCurrentDaysArrRes = (language) => {
  const d = new Date();
  let shortDaysArr = [];
  let dayNmonth = [];
  for (let i = 0; i < 8; i++) {
    let objShort =
      d.toLocaleString(language, { weekday: "short" }).charAt(0).toUpperCase() +
      d.toLocaleString(language, { weekday: "short" }).slice(1);
    // if (objShort.length > 3) objShort = objShort.slice(0, -1);
    shortDaysArr.push(objShort);
    let obj = {
      day: d.getDate() > 9 ? d.getDate() : "0" + d.getDate(),
      month: d.getMonth() + 1 > 9 ? d.getMonth() + 1 : "0" + (d.getMonth() + 1),
    };
    d.setDate(d.getDate() - 1);
    dayNmonth.push(obj);
  }
  let toReturn = {
    shortDays: shortDaysArr,
    dayNmonth: dayNmonth,
  };
  return toReturn;
};

export const x = () => {
  // var xxx = require("fs");
};
