import React, { useContext, useEffect, useState } from "react";
import { GetOfferBySport } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import { resolveIconForSingleRow } from "../../Helpers/Helpers";
import "./FrontPage.css";
import LandingContent from "./LandingContent";
import LeagueBigCard from "./LeagueBigCard";
import MenuMask from "./MenuMask";
import MenuMaskSingleItem from "./MenuMaskSingleItem";
import Ticket from "./Ticket";

const FrontPage = () => {
  const consumer = useContext(GeneralContext);

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMobileMenuOpen = () => setMobileMenuOpen(!mobileMenuOpen);

  const [mobileTicketOpen, setMobileTicketOpen] = useState(false);
  const handleMobileTicketOpen = () => setMobileTicketOpen(!mobileTicketOpen);

  const [events, setEvents] = useState(null);

  useEffect(() => {
    GetOfferBySport(0, 0, setEvents);
  }, []);

  return (
    <>
      <div className="rooooot">
        <div className="frontpage_root">
          <div className="frontpage_menuMask">
            <MenuMask />
          </div>
          <div className="frontpage_maincontent">
            <LandingContent events={events} />
          </div>
          <div className="frontpage_ticketMask">
            <Ticket />
          </div>
        </div>
      </div>

      <div className="mobile_fropntpage_root">
        <div className="mobile_frontpage_selectors">
          <div
            className="mobile_frontpage_selected_sport"
            onClick={() => handleMobileMenuOpen()}
          >
            {consumer.activeMenuItemSport[0].name}
          </div>
        </div>
        <div className="mobile_frontpage_content">
          <div className="landing_page_label_mobile">
            <span>TOP 20 CELE MAI PARIATE EVENIMENTE</span>
          </div>

          {events && (
            <div className="landing_page_events">
              {events.map((elem, key) => {
                return (
                  <div key={key}>
                    <LeagueBigCard
                      icon={resolveIconForSingleRow(elem.sportID)}
                      title={elem.leagueName}
                      label={elem.sportName}
                      events={elem.Events}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </div>
        <div
          className="mobile_frontpage_ticket"
          onClick={() => handleMobileTicketOpen()}
        >
          <div className="mobile_frontpage_ticket_events">
            {consumer.ticketContent[0] != null
              ? consumer.ticketContent[0][0].maxSystem
              : "0"}
          </div>
          <div className="mobile_frontpage_ticket_rows">
            <div className="mobile_fp_ticket_row_1">
              <div className="mobile_frontpage_ticket_label1">Cota</div>
              <div className="mobile_frontpage_ticket_label2">Miza</div>
              <div className="mobile_frontpagE_ticket_label3">
                Castig potential
              </div>
            </div>
            <div className="mobile_fp_ticket_row_2">
              <div className="mobile_frontpage_ticket_label1">
                {consumer.ticketContent[0] != null
                  ? consumer.ticketContent[0][0].cursTotal.toFixed(2)
                  : "0"}
              </div>
              <div className="mobile_frontpage_ticket_label2">
                {consumer.ticketStake[0]} <span>RON</span>
              </div>
              <div className="mobile_frontpagE_ticket_label3">
                {consumer.ticketContent[0] != null
                  ? consumer.ticketContent[0][0].castigMax
                  : "0"}{" "}
                <span>RON</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="mobile_frontpage_menu_mask">
          <MenuMask />
        </div>
      )}
      {mobileTicketOpen && (
        <div className="mobile_ticket_opened">
          <Ticket handleMobileTicketOpen={handleMobileTicketOpen} />
        </div>
      )}
    </>
  );
};

export default FrontPage;
