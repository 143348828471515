import axios from "axios";

export const GetMenuItems = async (day, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/frontOffer/menu?day=" +
        `${day}`
    )
    .then((response) => setter(response.data));
};

export const GetOfferBySport = async (sportId, day, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/frontOffer/offerBySport?sportId=" +
        `${sportId}` +
        "&day=" +
        `${day}`
    )
    .then((response) => setter(response.data));
};

export const GetAuxTicketSerial = async (setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/tickets/serials?clientId=0"
    )
    .then((respone) => setter(respone.data));
};

export const EvaluateTicket = async (spStake, setter) => {
  await axios
    .post(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/tickets/updateTicket?finalizeTicket=0",
      spStake,
      null
    )
    .then((respone) => setter(respone.data));
};

export const GetOfferByLeague = async (leagueId, day, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/frontOffer/offerByLeagues?leaguesIds=" +
        `${leagueId}` +
        "&day=" +
        `${day}`
    )
    .then((respone) => setter(respone.data));
};

export const GetAllOddsForEvent = async (eventId, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/mainOffer/eventMarketsV2?matchId=" +
        `${eventId}` +
        "&onlySpecials=false"
    )
    .then((respone) => setter(respone.data));
};

export const PlaceBet = async (spStake, setter) => {
  await axios
    .post(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/tickets/updateTicket?finalizeTicket=0",
      spStake,
      null
    )
    .then((respone) => setter(respone.data));
};

export const VerifyTicket = async (serial, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/tickets/ticketContent?ticketSerial=" +
        `${serial}`
    )
    .then((response) => setter(response.data));
};

//Loto-API

export const GetLotoMenu = async (day, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacyLotto/offer/lotteries?day=" +
        `${day}`
    )
    .then((response) => setter(response.data));
};

export const GetOfferByLottery = async (day, lotteryId, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacyLotto/offer/lottery/schedules?day=" +
        `${day}` +
        "&lotteryId=" +
        `${lotteryId}`
    )
    .then((response) => setter(response.data));
};

export const EvaluateLotoTicket = async (
  id,
  amount,
  ltStake,
  system,
  setter
) => {
  let numbers = ltStake.length > 1 ? ltStake.join("%2C") : ltStake.join();
  let sys = system.length > 1 ? system.join("%2C") : system.join();

  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacyLotto/tickets/evaluate?lotteryId=" +
        `${id}` +
        "&playedAmount=" +
        `${amount}` +
        "&numbers=" +
        `${numbers}` +
        "&system=" +
        `${sys}`
    )
    .then((response) => setter(response.data));
};

export const GetStatistics = async (id, key, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacyLotto/offer/stats?lotteryId=" +
        `${id}` +
        "&key=" +
        `${key}`
    )
    .then((response) => setter(response.data));
};

export const GetAuxLotoSerial = async (setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacyLotto/tickets/serials?clientId=0"
    )
    .then((respone) => setter(respone.data));
};

export const PlaceLotoBet = async (
  system,
  serial,
  amount,
  eventId,
  numbers,
  setter
) => {
  let ltStake = {
    ticketSystem: system,
    ticketSerial: serial,
    ticketAmount: amount,
    eventID: eventId,
    choosedNumbers: numbers,
  };

  await axios
    .post(
      "https://clv-legacywebapi.syncrobet.ro/LegacyLotto/tickets/placeBet",
      ltStake,
      null
    )
    .then((respone) => setter(respone.data));
};

export const GetResultsMenuItems = async (ddmmyy, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/results/menu?ddMMyy=" +
        `${ddmmyy}`
    )
    .then((respone) => {
      setter(respone.data);
    });
};
export const GetResultsContent = async (spId, ddmmyy, setter) => {
  await axios
    .get(
      "https://clv-legacywebapi.syncrobet.ro/LegacySports/results/events?sportId=" +
        `${spId}` +
        "&ddMMyy=" +
        `${ddmmyy}`
    )
    .then((response) => setter(response.data));
};

export const sendInfo = (nume, mail, mesaj) => {
  axios
    .post(
      "http://cloverbet.ro/send-info",
      {
        nume: nume,
        email: mail,
        mesaj: mesaj,
      },
      null
    )
    .then((respone) => {
      let x = respone.data;
    });
};

export const GetJackpots = async (setter) => {
  await axios
    .get("https://clv-legacywebapi.syncrobet.ro/LegacySports/misc/jackpots")
    .then((response) => setter(response.data));
};
