import React, { useContext, useEffect, useState } from "react";
import { GetAuxLotoSerial, PlaceLotoBet } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import {
  AddToLotoStake,
  convertUtcToSingleRowTime,
} from "../../Helpers/Helpers";
import "./Loto.css";

const TicketLoto = (props) => {
  const consumer = useContext(GeneralContext);

  const [lotoSerial, setLotoSerial] = useState(null);

  const renderTicketBalls = () => {
    if (consumer.ltStake[0].length === 0)
      return <div className="ticket_loto_ticket_empty">Biletul este gol</div>;
    else
      return consumer.ltStake[0].map((el, i) => {
        return (
          <div
            className="ticket_loto_ticket_ball"
            key={i}
            onClick={() => {
              AddToLotoStake(
                consumer.ltStake[0],
                consumer.ltStake[1],
                el,
                consumer.lotoActiveItem[0].max
              );
            }}
          >
            {el}
          </div>
        );
      });
  };

  useEffect(() => {
    consumer.ltStake[1]([]);
  }, [consumer.lotoSelectedEvent[0]]);

  useEffect(() => {
    let xx = {
      lotteryDate: null,
      lotteryName: null,
      lotterySystem: null,
    };
    consumer.lotoSelectedDetails[1](xx);
    consumer.ltStake[1]([]);
    consumer.lotoSelectedEvent[1](null);
    consumer.lotoEvaluate[1](null);
  }, [consumer.lotoActiveItem[0]]);

  const cancelTicket = () => {
    consumer.ltStake[1]([]);
    consumer.lotoEvaluate[1](null);
    consumer.checkBoxesArray[1]([]);
  };

  const addOrRemoveCheckbox = (index, setter) => {
    let idx = -1;
    let aux = [...consumer.checkBoxesArray[0]];
    if (consumer.checkBoxesArray[0].indexOf(index) > -1) {
      idx = consumer.checkBoxesArray[0].indexOf(index);
      aux.splice(idx, 1);
      setter(aux);
    } else {
      aux.push(index);
      setter(aux);
    }
  };

  const generateCheckBoxez = () => {
    let defaultChecked = [];

    if (
      consumer.ltStake[0].length <= parseInt(consumer.lotoActiveItem[0].maxBet)
    ) {
      for (let i = 0; i < consumer.ltStake[0].length; i++) {
        defaultChecked.push(
          <div className="ticket_loto_combinations_single_elem" key={i}>
            <input
              type="checkbox"
              id={i}
              name={"checkbox" + (i + 1)}
              className="ticket_loto_combinations_inpus"
              onChange={() => {
                addOrRemoveCheckbox(i + 1, consumer.checkBoxesArray[1]);
              }}
              checked={
                consumer.checkBoxesArray[0].indexOf(i + 1) > -1 ? true : false
              }
            />
            <label htmlFor={"checkbox" + i}>
              {i + 1}/{consumer.ltStake[0].length}
            </label>
          </div>
        );
      }
    } else {
      for (let i = 0; i < consumer.lotoActiveItem[0].maxBet; i++) {
        defaultChecked.push(
          <div className="ticket_loto_combinations_single_elem" key={i}>
            <input
              type="checkbox"
              id={i}
              name={"checkbox" + (i + 1)}
              className="ticket_loto_combinations_inpus"
              onChange={() => {
                addOrRemoveCheckbox(i + 1, consumer.checkBoxesArray[1]);
              }}
              checked={
                consumer.checkBoxesArray[0].indexOf(i + 1) > -1 ? true : false
              }
            />
            <label htmlFor={"checkbox" + i}>
              {i + 1}/{consumer.ltStake[0].length}
            </label>
          </div>
        );
      }
    }

    return defaultChecked;
  };

  useEffect(() => {
    GetAuxLotoSerial(setLotoSerial);
  }, []);

  useEffect(() => {
    if (lotoSerial != null) {
      consumer.lotoSerial[1](lotoSerial);
    }
  }, [lotoSerial]);

  return (
    <div className="ticket_loto_root">
      <div className="ticket_loto_verify">
        <div className="ticket_loto_verif_label">Verificare bilet</div>
        <input
          type="text"
          placeholder="Cod bilet"
          className="ticket_loto_verif_input"
        />
        <div className="ticket_loto_verif_button">&#62;</div>
      </div>
      <div
        className="mobile_ticket_close"
        onClick={() => props.handleMobileTicketOpen()}
      >
        <img src="./downarr.png" width="21px" height="21px" />
        <span>Inchide</span>
      </div>
      <div className="ticket_loto_ticket_root">
        <div className="ticket_loto_t_header">BILET VIRTUAL</div>
        <div className="ticket_loto_t_actions">
          <div className="ticket_loto_t_events">
            Numere: {consumer.ltStake[0].length}
          </div>
          <div
            className="ticket_loto_t_anuleaza"
            onClick={() => cancelTicket()}
          >
            <div className="ticket_loto_t_anuleaza_label">Anuleaza</div>
            <img
              src="./bin.png"
              width="18px"
              height="18px"
              className="ticket_loto_t_anuleaza_icon"
            />
          </div>
        </div>
        {consumer.lotoSelectedDetails[0].lotteryDate != null && (
          <div className="ticket_loto_t_lottery_info">
            <div className="ticket_loto_t_lottery_date">
              {convertUtcToSingleRowTime(
                consumer.lotoSelectedDetails[0].lotteryDate,
                "ro"
              )}
            </div>
            <div className="ticket_loto_t_lottery_name">
              {consumer.lotoSelectedDetails[0].lotteryName}
            </div>
            <div className="ticket_loto_t_lottery_sys">
              <div className="ticket_loto_t_lottery_sys_draws">
                {consumer.lotoSelectedDetails[0].lotterySystem.split("/")[0]}
              </div>
              <div className="ticket_loto_t_lottery_sys_outOf">
                {consumer.lotoSelectedDetails[0].lotterySystem.split("/")[1]}
              </div>
            </div>
          </div>
        )}
        <div className="ticket_loto_t_content">{renderTicketBalls()}</div>
        <div className="ticket_loto_checkboxes">{generateCheckBoxez()}</div>
        {consumer.lotoEvaluate[0] != null && (
          <div className="ticket_loto_stake">
            <div className="ticket_loto_combinatii">
              <div className="ticket_loto_combinatii_label">Nr. Combinatii</div>
              <div className="ticket_loto_combinatii_value">
                {consumer.lotoEvaluate[0].combinations}
              </div>
            </div>
            <div className="ticket_loto_miza">
              <div className="ticket_loto_miza_label">Miza:</div>
              <div className="ticket_loto_miza_section">
                <div className="ticket_loto_miza_ccy">RON</div>
                <input
                  type="number"
                  inputMode="decimal"
                  autoComplete="off"
                  className="ticket_loto_miza_input"
                  min={1}
                  defaultValue={2}
                  onChange={(e) => {
                    consumer.lotoTicketStake[1](e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="ticket_loto_infos">
              <div className="ticket_loto_infos_tax">
                Taxa CDA: 5.00% ={" "}
                {((5 / 100) * consumer.lotoTicketStake[0]).toFixed(2)}
              </div>
              <div className="ticket_loto_infos_stakeafter">
                Miza dupa aplicarea taxei:{" "}
                {(
                  consumer.lotoTicketStake[0] -
                  (5 / 100) * consumer.lotoTicketStake[0]
                ).toFixed(2)}
              </div>
            </div>
          </div>
        )}

        {consumer.lotoEvaluate[0] != null && (
          <div className="ticket_loto_buttons">
            <div className="ticket_loto_potential">
              <div className="ticket_loto_potential_label">
                Castig potential:
              </div>
              <div className="ticket_loto_potential_value">
                {consumer.lotoEvaluate[0].maxWin} <span>RON</span>
              </div>
            </div>
            <div className="ticket_loto_print_button">
              <div
                className="ticket_loto_print_btn_wrap"
                onClick={() => {
                  PlaceLotoBet(
                    consumer.checkBoxesArray[0].join(),
                    consumer.lotoSerial[0],
                    consumer.lotoTicketStake[0],
                    consumer.lotoSelectedEvent[0],
                    consumer.ltStake[0].join(),
                    consumer.lotoPlaceBetResponse[1]
                  );
                }}
              >
                PLASEAZA PARIUL
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TicketLoto;
