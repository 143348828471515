import React, { useContext, useEffect, useState } from "react";
import { GetResultsContent } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import ResultsContentSingleItem from "./ResultsContentSingleItem";
import ResultsMenuSingleItem from "./ResultsMenuSingleItem";

const ResultsContent = () => {
  const [results, setResults] = useState(null);
  const consumer = useContext(GeneralContext);

  useEffect(() => {
    let x = new Date();
    const yy = x.getFullYear();
    let mm = x.getMonth() + 1;
    let dd = x.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    let date = dd + "" + mm + "" + yy.toString().substr(-2);

    GetResultsContent(consumer.activeResultsMenuItem[0], date, setResults);
  }, []);

  useEffect(() => {
    let xx = consumer.dateSelectorRes[0].indexOf(1);
    let dd = new Date();
    dd.setDate(dd.getDate() - xx);

    const yy = dd.getFullYear();
    let mm = dd.getMonth() + 1;
    let ddd = dd.getDate();

    if (ddd < 10) ddd = "0" + ddd;
    if (mm < 10) mm = "0" + mm;

    let final = ddd + "" + mm + "" + yy.toString().substr(-2);

    GetResultsContent(consumer.activeResultsMenuItem[0], final, setResults);
  }, [consumer.dateSelectorRes[0]]);

  useEffect(() => {
    let xx = consumer.dateSelectorRes[0].indexOf(1);
    let dd = new Date();
    dd.setDate(dd.getDate() - xx);
    const yy = dd.getFullYear();
    let mm = dd.getMonth() + 1;
    let ddd = dd.getDate();

    if (ddd < 10) ddd = "0" + ddd;
    if (mm < 10) mm = "0" + mm;

    let final = ddd + "" + mm + "" + yy.toString().substr(-2);

    GetResultsContent(consumer.activeResultsMenuItem[0], final, setResults);
  }, [consumer.activeResultsMenuItem[0]]);

  const renderStuff = () => {
    if (results) {
      if (results[0].sportName) {
        return results.map((element, i) => {
          return (
            <div key={i}>
              <div className="name">{element.sportName}</div>
              {element.results.map((el, k) => {
                return (
                  <div key={k}>
                    <ResultsContentSingleItem elem={el} />
                  </div>
                );
              })}
            </div>
          );
        });
      } else {
        return results.map((element, i) => {
          return (
            <div key={i}>
              <ResultsContentSingleItem elem={element} />
            </div>
          );
        });
      }
    }
  };

  return (
    results && (
      <div className="results_content_root">
        <div className="results_content_header">
          <div className="results_content_date">DATA</div>
          <div className="results_content_code">COD</div>
          <div className="results_content_sport">LIGA</div>
          <div className="results_content_name">EVENIMENT</div>
          <div className="results_content_odds">COTE</div>
        </div>
        <div className="results_content_content">{renderStuff()}</div>
      </div>
    )
  );
};

export default ResultsContent;
