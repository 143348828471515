import React, { useEffect, useState } from "react";
import { RemoveFromTicket } from "../../Helpers/Helpers";

const TicketLine = (props) => {
  const [clock, setClock] = useState(null);

  useEffect(() => {
    let xx = new Date(props.event.eventTime);
    setClock(new Date(props.event.eventTime));
  }, []);

  return (
    clock && (
      <div className="ticket_line_root">
        <div className="ticket_line_header">
          <div className="ticket_line_date">
            {clock.toLocaleTimeString(["ro-RO"], {
              hour12: false,
              hour: "2-digit",
              minute: "2-digit",
              weekday: "short",
            })}
          </div>
          <div className="ticket_line_code">{props.event.offerNumber}</div>
          {props.for != "modal" && (
            <div
              className="ticket_line_delete"
              onClick={() => {
                if (props.stake.eventsArray.split(",").length > 2) {
                  RemoveFromTicket(
                    props.stake,
                    props.setStake,
                    props.event.eventID
                  );
                } else {
                  props.cancelTicket();
                }
              }}
            >
              <img src="./trash_bin.png" width="18px" height="18px" />
            </div>
          )}
        </div>
        <div className="ticket_line_match">
          <div className="ticket_line_match_info">
            <div className="ticket_line_match_info_home">
              {props.event.eventName.split("-")[0]}
            </div>
            <div className="ticket_line_match_info_home">
              {props.event.eventName.split("-")[1]}
            </div>
          </div>
          <div className="ticket_line_match_extras">
            {/* Aici o sa vina dumele alea 2 ale tale */}
          </div>
        </div>
        <div className="ticket_line_markets">
          <div className="ticket_line_market_def">{props.event.marketName}</div>
          <div className="ticket_line_market_desc">{props.event.type}</div>
          <div className="ticket_line_market_odd">
            {props.event.currentPrice}
          </div>
        </div>
      </div>
    )
  );
};

export default TicketLine;
