import React, { useContext, useEffect, useState } from "react";
import { GetStatistics } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import { AddToLotoStake } from "../../Helpers/Helpers";

const LotoPlayTable = (props) => {
  let xx = new Array(parseInt(props.ballsNo)).fill(0);
  const consumer = useContext(GeneralContext);

  const [randomLabel, setRandomLabel] = useState(5);

  const [statistics, setStatistics] = useState(null);

  const handlePlusClick = () => {
    if (randomLabel < consumer.lotoActiveItem[0].max) {
      setRandomLabel(randomLabel + 1);
    }
  };

  const handleMinusClick = () => {
    if (randomLabel > 1) {
      setRandomLabel(randomLabel - 1);
    }
  };

  const getRandomNumbers = () => {
    let arr = [];
    let nums = props.elem.system.split("/")[1];

    consumer.ltStake[1]([]);
    consumer.checkBoxesArray[1]([]);

    while (arr.length < randomLabel) {
      var r = Math.floor(Math.random() * nums) + 1;
      if (arr.indexOf(r) === -1) arr.push(r);
    }

    consumer.ltStake[1](arr);
  };

  useEffect(() => {
    if (statistics) {
      let arr = [];

      consumer.ltStake[1]([]);
      consumer.checkBoxesArray[1]([]);

      let nums = statistics.split(",");
      for (let i = 0; i < nums.length; i++) {
        arr.push(parseInt(nums[i]));
      }

      consumer.ltStake[1](arr);
    }
  }, [statistics]);

  return (
    <div
      className="loto_playtable_root"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="loto_playtable_header">
        {props.clock && (
          <div className="loto_play_header_clock">
            Runda se inchide in <span>{props.clock}</span> minute
          </div>
        )}
        <div className="loto_play_header_max">
          Maxim <span>{consumer.lotoActiveItem[0].max}</span> numere
        </div>
      </div>
      <div className="loto_playtable_actions">
        <div className="loto_play_action_random">
          <div className="loto_play_random_lbl1">Numar de bile</div>
          <div className="loto_play_random_pills">
            <div
              className="loto_play_pills_minus"
              onClick={(e) => {
                e.stopPropagation();
                handleMinusClick();
              }}
            >
              -
            </div>
            <div className="loto_play_pills_value">{randomLabel}</div>
            <div
              className="loto_play_pills_plus"
              onClick={(e) => {
                e.stopPropagation();
                handlePlusClick();
              }}
            >
              +
            </div>
          </div>
          <div
            className="loto_play_random_button"
            onClick={(e) => {
              e.stopPropagation();
              getRandomNumbers();
            }}
          >
            Alegere rapida
          </div>
        </div>
        <div className="loto_play_action_stats">
          <div
            className="loto_play_stats_1"
            onClick={() => {
              GetStatistics(consumer.lotoActiveItem[0].id, "R5", setStatistics);
            }}
          >
            Cele mai rare 5 numere
          </div>
          <div
            className="loto_play_stats_2"
            onClick={() => {
              GetStatistics(consumer.lotoActiveItem[0].id, "C5", setStatistics);
            }}
          >
            Cele mai frecvente 5 numere
          </div>
          <div
            className="loto_play_stats_3"
            onClick={() => {
              GetStatistics(consumer.lotoActiveItem[0].id, "F2", setStatistics);
            }}
          >
            Pereche frecventa
          </div>
          <div
            className="loto_play_stats_4"
            onClick={() => {
              GetStatistics(consumer.lotoActiveItem[0].id, "F3", setStatistics);
            }}
          >
            Trio frecvent
          </div>
          <div
            className="loto_play_stats_5"
            onClick={() => {
              GetStatistics(
                consumer.lotoActiveItem[0].id,
                "FC2",
                setStatistics
              );
            }}
          >
            Pereche consecutiva frecventa
          </div>
          <div
            className="loto_play_stats_6"
            onClick={() => {
              GetStatistics(
                consumer.lotoActiveItem[0].id,
                "FC3",
                setStatistics
              );
            }}
          >
            Trio consecutiv frecvent
          </div>
        </div>
      </div>
      <div className="loto_playtable_playtable">
        {xx.map((elem, key) => {
          return (
            <div
              key={key}
              className={
                consumer.ltStake[0].indexOf(key + 1) != -1
                  ? "loto_playtable_value loto_playtable_value_selected"
                  : "loto_playtable_value"
              }
              onClick={(e) => {
                e.stopPropagation();
                AddToLotoStake(
                  consumer.ltStake[0],
                  consumer.ltStake[1],
                  key + 1,
                  consumer.lotoActiveItem[0].max
                );
              }}
            >
              {key + 1}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default LotoPlayTable;
