import React, { useEffect } from "react";
import { useContext } from "react";
import { GeneralContext } from "../App";
import { Modal } from "@mui/material";
import Barcode from "react-barcode";
const ModalLoto = (props) => {
  const consumer = useContext(GeneralContext);

  return (
    consumer.lotoPlaceBetResponse[0] && (
      <Modal
        open={props.open}
        onClose={props.onClose}
        className="modal_ticket_root"
      >
        <div className="modal_ticket_container">
          <div className="modal_ticket_header">
            <div className="modal_ticket_titles">
              <div className="modal_ticket_sport">
                LOTO |{" "}
                {consumer.lotoPlaceBetResponse[0].header.nrVariante === 1
                  ? "SIMPLU"
                  : "SYSTEM"}
              </div>
              <div
                className="modal_ticket_close"
                onClick={() => {
                  props.onClose();
                  consumer.lotoPlaceBetResponse[1](null);
                }}
              >
                X
              </div>
            </div>
          </div>
          <div className="modal_ticket_events">
            <div className="modal_ticket_event">
              Numar de variante:{" "}
              <span>{consumer.lotoPlaceBetResponse[0].header.nrVariante}</span>
            </div>
          </div>
          <div className="modal_ticket_content">
            <div className="modal_ticket_left">
              {consumer.ltStake[0].map((el, i) => {
                return (
                  <div className="ticket_loto_ticket_ball_modal" key={i}>
                    {el}
                  </div>
                );
              })}
              <div className="modal_ticket_left_loto_stuff">
                {consumer.lotoPlaceBetResponse[0].ticketLines.map((el, i) => {
                  return <div>{el.winInfo}</div>;
                })}
              </div>
            </div>
            <div className="modal_ticket_right">
              <div className="modal_ticket_barcode">
                <Barcode value={consumer.lotoSerial[0]} />
              </div>
              <div className="modal_ticket_informations">
                <div className="modal_ticket_total_odd">
                  Nr variante:
                  <span>
                    {consumer.lotoPlaceBetResponse[0].header.nrVariante}
                  </span>
                </div>
                {/* <div className="modal_ticket_system_inf">
                  System:{" "}
                  <span>
                    {ticketData.correctSystem === ""
                      ? ticketData.systemWinInfo
                      : ticketData.correctSystem + "/" + ticketData.maxSystem}
                  </span>
                </div> */}
                <div className="modal_ticket_miza_inf">
                  Miza:
                  <span>
                    {consumer.lotoPlaceBetResponse[0].header.sumaBilet} RON
                  </span>
                </div>
                <div className="modal_ticket_tax_cda">
                  Tax CDA:
                  <span>
                    {(
                      consumer.lotoPlaceBetResponse[0].header.sumaBilet -
                      consumer.lotoPlaceBetResponse[0].header.mizaBilet
                    ).toFixed(2)}{" "}
                    RON
                  </span>
                </div>
                <div className="modal_ticket_miza_after">
                  Miza dupa aplicarea taxei:{" "}
                  <span>
                    {consumer.lotoPlaceBetResponse[0].header.mizaBilet} RON
                  </span>
                </div>
                <div className="modal_ticket_min_win">
                  Casting potential min.{" "}
                  <span>
                    {consumer.lotoPlaceBetResponse[0].header.castigMin} RON
                  </span>
                </div>
              </div>

              <div className="modal_ticket_buttons">
                <div className="modal_ticket_max_win">
                  Castig potential:{" "}
                  <span>
                    {consumer.lotoPlaceBetResponse[0].header.castigMax} RON
                  </span>
                </div>
                <div className="modal_ticket_btns">
                  <div
                    className="modal_ticket_btn_close"
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    INCHIDE
                  </div>
                  {/* <div className="modal_ticket_btn_print" onClick={()=>alert("WAZZZZZA")}>PRINTARE</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    )
  );
};

export default ModalLoto;
