import React, { useEffect, useState } from "react";
import { GetJackpots } from "../../API/ApiManager";
import CarouselComp from "./Carousel";
import Info from "./Info";
import "./Presentation.css";

const Presentation = () => {
  const [jack, setJack] = useState(null);
  useEffect(() => {
    GetJackpots(setJack);
  }, []);

  setTimeout(() => {
    GetJackpots(setJack);
  }, 300000);

  return (
    jack && (
      <div className="presentation_root ">
        <div className="p_nav">
          {/* <img src="./images/logo.png" alt="" className="p_logo" /> */}
          <a
            className="p_nav_p"
            href="https://syncrogames.com/"
            target="_blank"
          >
            <img src="./synclogo.png" alt="" className="p_logo" />
          </a>
        </div>
        <CarouselComp />
        <Info response={jack} />
      </div>
    )
  );
};

export default Presentation;
