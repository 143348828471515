import React, { useContext, useEffect, useState } from "react";
import { GetOfferByLottery } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import DateSelector from "../Sport/DateSelector";
import LotoContentSingleItem from "./LotoContentSingleItem";

const LotoContent = () => {
  const consumer = useContext(GeneralContext);
  const [events, setEvents] = useState(null);

  useEffect(() => {
    GetOfferByLottery(
      consumer.dateSelector[0].indexOf(1),
      consumer.lotoActiveItem[0].id,
      setEvents
    );
  }, []);

  useEffect(() => {
    GetOfferByLottery(
      consumer.dateSelector[0].indexOf(1),
      consumer.lotoActiveItem[0].id,
      setEvents
    );
  }, [consumer.dateSelector[0]]);

  useEffect(() => {
    GetOfferByLottery(
      consumer.dateSelector[0].indexOf(1),
      consumer.lotoActiveItem[0].id,
      setEvents
    );
  }, [consumer.lotoActiveItem[0]]);

  return (
    <div className="loto_content_root">
      <div className="loto_content_date_selector">
        <DateSelector />
      </div>
      <div className="loto_content_infos">
        <div className="loto_content_date">Data</div>
        <div className="loto_content_cod">Cod</div>
        <div className="loto_content_name">Loterie</div>
        <div className="loto_content_system">Sistem</div>
        <div className="loto_content_odds">
          <div className="loto_content_odd">1</div>
          <div className="loto_content_odd">2</div>
          <div className="loto_content_odd">3</div>
          <div className="loto_content_odd">4</div>
          <div className="loto_content_odd">5</div>
          <div className="loto_content_odd">6</div>
          <div className="loto_content_odd">7</div>
          <div className="loto_content_odd">8</div>
        </div>
      </div>
      {events && (
        <div className="loto_content_content">
          {events.map((element, key) => {
            return (
              <div
                key={key}
                onClick={() => {
                  if (consumer.lotoSelectedEvent[0] === element.id) {
                    consumer.lotoSelectedEvent[1](null);
                    let yy = {
                      lotteryDate: null,
                      lotteryName: null,
                      lotterySystem: null,
                    };
                    consumer.lotoSelectedDetails[1](yy);
                  } else {
                    consumer.lotoSelectedEvent[1](element.id);
                    let xx = {
                      lotteryDate: element.date,
                      lotteryName: element.lotteryName,
                      lotterySystem: element.system,
                    };
                    consumer.lotoSelectedDetails[1](xx);
                  }
                }}
              >
                <LotoContentSingleItem elem={element} />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LotoContent;
