import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "../../App";
import { getCurrentDaysArrRes } from "../../Helpers/Helpers";

const ResultsDateSelector = (props) => {
  const [shortDays, setShortDays] = useState(null);
  const consumer = useContext(GeneralContext);
  useEffect(() => {
    setShortDays(getCurrentDaysArrRes("RO-ro"));
  }, []);

  const formatDateSelector = (index) => {
    return (
      shortDays && (
        <span style={{ fontWeight: 600 }}>
          {shortDays.shortDays[index]}{" "}
          <span style={{ fontWeight: 300 }}>
            {shortDays.dayNmonth[index].day +
              "." +
              shortDays.dayNmonth[index].month}
          </span>
        </span>
      )
    );
  };
  const resolveClassName = (index) => {
    if (consumer.dateSelectorRes[0][index] === 0) return "date_selector_button";
    return "date_selector_button date_selector_is_active";
  };

  const toggleActiveDate = (index) => {
    let aux = [0, 0, 0, 0, 0, 0, 0, 0];
    aux[index] = 1;
    let setter = consumer.dateSelectorRes[1];
    setter(aux);
  };
  const generateDay = (index, title) => {
    return (
      <div
        className={resolveClassName(index)}
        onClick={() => {
          toggleActiveDate(index);
          props.handleDateMenuOpen();
        }}
      >
        {title}
      </div>
    );
  };
  return (
    <div className="results_date_selector">
      {generateDay(0, "Astazi")}
      {generateDay(1, formatDateSelector(1))}
      {generateDay(2, formatDateSelector(2))}
      {generateDay(3, formatDateSelector(3))}
      {generateDay(4, formatDateSelector(4))}
      {generateDay(5, formatDateSelector(5))}
      {generateDay(6, formatDateSelector(6))}
      {generateDay(7, formatDateSelector(7))}
    </div>
  );
};

export default ResultsDateSelector;
