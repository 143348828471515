import React, { useEffect, useState } from "react";

const TicketLineV2 = (props) => {
  const [clock, setClock] = useState(null);

  useEffect(() => {
    let xx = new Date(props.event.matchTime);
    setClock(new Date(props.event.matchTime));
  }, []);
  return (
    clock && (
      <div className="ticket_line_root">
        <div className="ticket_line_header">
          <div className="ticket_line_date">
            {clock.toLocaleTimeString(["ro-RO"], {
              hour12: false,
              hour: "2-digit",
              minute: "2-digit",
              weekday: "short",
            })}
          </div>
          <div className="ticket_line_code">{props.event.offerNumber}</div>
        </div>
        <div className="ticket_line_match">
          <div className="ticket_line_match_info">
            <div className="ticket_line_match_info_home">
              {props.event.eventDesc.split("-")[0]}
            </div>
            <div className="ticket_line_match_info_home">
              {props.event.eventDesc.split("-")[1]}
            </div>
          </div>
          <div className="ticket_line_match_extras">
            {/* Aici o sa vina dumele alea 2 ale tale */}
          </div>
        </div>
        <div className="ticket_line_markets">
          <div className="ticket_line_market_def">MARKET</div>
          <div className="ticket_line_market_desc">{props.event.type}</div>
          <div className="ticket_line_market_odd">{props.event.oddValue}</div>
        </div>
      </div>
    )
  );
};

export default TicketLineV2;
