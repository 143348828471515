import React from "react";

const VirtualeSix = () => {
  return (
    <div className="virtuale_six_root">
      <div className="virtuale_six_header">CLOVER 6</div>
      <div className="virtuale_six_content">
        <div className="virtuale_six_image">
          <img src="./assets/clover.jpg" className="virtuale_six_image_img" />
        </div>
        <div className="virtuale_six_text">
          <p>
            Clover6 este un joc virtual ce consta intr-un pariu pe numere in
            cota fixa, Acestea sunt extrase simultan si pot fi vizualizate in
            toate agentiile Clover Bet.
          </p>
          <p>
            Clover6 functioneaza pe sistemul unei extrageri loto, extragandu-se
            35 de numere dintr-un total de 49. Acestea se extrag pe rand si sunt
            afisate unul cate unul. Primele 5 numere extrase nu sunt insotite de
            cote, insa urmatoarele 30 au afisate in dreptul lor cotele pe care
            se determina calcului castigului de pe bilet.
          </p>
          <p>
            Extragerile se realizeaza o data la cinci minute, pe toata durata
            programului de lucru a agentiei iar desfasurarea efectiva a unei
            astfel de extrageri, precum si afisarea rezultatelor dureaza 2
            minute si 30 de secunde. Intre extrageri, pe ecranul televizoarelor
            sunt prezentate informatii utile despre acest tip de pariu virtual
            si despre posibilitatile de pariere.
          </p>
          <p>
            Pentru a fi desemnat castigator, un pariu pe Clover6 presupune
            indicarea corecta a cel putin 6 numere dintre cele 35 extrase,
            jucatorii putand opta pentru parierea intre 6 si 10 numere pe un
            bilet. Acestea pot fi indicate de catre parior, sau pot fi alese in
            mod aleatoriu de catre sistemul de pariere.
          </p>
          <p>
            Suma minima ce poate fi pariata pe un astfel de pariu Clover6 este
            de 2 lei, iar valoarea castigurilor nu este influentata de sumele
            pariate, ci se determina intodeauna prin inmultirea cotei celui
            de-al saselea număr extras cu miza pariata.
          </p>
          <h3>Tipuri de pariuri Clover6</h3>
          <h3>Pariul simplu</h3>
          <p>
            Se vor alege 6 numere pe bilet iar, in cazul in care toate acestea
            vor fi extrase, suma castigata va fi inmultita cu cota ulimului
            numar extras de pe bilet.
          </p>
          <h3>Pariul combinat</h3>
          <p>
            Acest pariu se refera la alegerea la 7, 8, 9 sau 10 numere pe bilet.
            In cazul alegerii a 10 numere pe bilet, se vor face combinatii de
            cate 6 variante, utilizand toate posibilitatile de combinare ale
            celor 10 numere alese.
          </p>
          <h3>Pariul culoare</h3>
          <p>
            In cazul pariului culoare, pronosticul se va pune pe culoarea primei
            bile extrase.
          </p>
          <h3>Pariul primul numar sub/peste 24,5</h3>
          <p>
            Ca si in cazul pariului culoare, acest pronostic se refera la prima
            bila extrasa. Se poate alege daca aceasta va fi sub 24.5 sau peste.
          </p>
          <h3>Pariul suma primelor 5 numere sub/peste 124,5.</h3>
          <p>
            Pariul stabileste daca suma primelor 5 numere extrase este sub, sau
            peste 124,5.
          </p>
          <h3>Pariul PAR/IMPAR</h3>
          <p>Primul numar extras va fi par sau impar?</p>
          <h3>Trifoi</h3>
          <p>
            La fiecare extragere, in dreptul a doua cote va aparea simbolul
            trifoi. In cazul in care biletul este castigator, iar ultima bila
            extrasa de pe bilet va pica in dreptul trifoiului, acesta aduce un
            castig dublu. Astfel, cota pe care a picat ultimul numar va fi
            inmultita cu 2. Bilele extrase pentru trifoi vor fi afisate, in
            timpul jocului, pe aceeasi bara cu primele 5 bile extrase.
          </p>
          <h3>Bonus</h3>
          <p>
            Bonusul se acorda periodic. Chiar daca biletul este declarat
            necastigator, el poate deveni castigator in cazul in care codul
            format din cele 4 litere de pe acesta va fi indicat de catre sistem
            la finalul extragerii.
          </p>
        </div>
      </div>
    </div>
  );
};

export default VirtualeSix;
