import React, { useState } from "react";
import "./Virtuale.css";
import VirtualeCanini from "./VirtualeCanini";
import VirtualeKeno from "./VirtualeKeno";
import VirtualeRuleta from "./VirtualeRuleta";
import VirtualeSix from "./VirtualeSix";

const Virtuale = () => {
  const [activeMenuItem, setActiveMenuItem] = useState([1, 0, 0, 0]);
  const toggleActiveItem = (idx) => {
    let aux = [0, 0, 0, 0];
    aux[idx] = 1;
    setActiveMenuItem(aux);
  };

  const resolveClassName = (index) => {
    let i = activeMenuItem.indexOf(1);
    if (i === index) return "virtuale_menu_item menu_active";
    return "virtuale_menu_item";
  };

  const conditionalRender = () => {
    let i = activeMenuItem.indexOf(1);
    switch (i) {
      case 0:
        return <VirtualeSix />;
        break;
      case 1:
        return <VirtualeRuleta />;
        break;
      case 2:
        return <VirtualeKeno />;
        break;
      case 3:
        return <VirtualeCanini />;
        break;
      default:
        break;
    }
  };

  return (
    <div className="virtuale_root">
      <div className="virtuale_wrap">
        <div className="virtuale_selectors">
          <div
            className={resolveClassName(0)}
            onClick={() => toggleActiveItem(0)}
          >
            CLOVER 6
          </div>
          <div
            className={resolveClassName(1)}
            onClick={() => toggleActiveItem(1)}
          >
            CLOVER RULETA
          </div>
          <div
            className={resolveClassName(2)}
            onClick={() => toggleActiveItem(2)}
          >
            CLOVER KENO
          </div>
          <div
            className={resolveClassName(3)}
            onClick={() => toggleActiveItem(3)}
          >
            CURSE DE CAINI
          </div>
        </div>
        <div className="virtuale_content">{conditionalRender()}</div>
      </div>
    </div>
  );
};

export default Virtuale;
