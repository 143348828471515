import React, { useContext, useEffect } from "react";
import { GeneralContext } from "../../App";
import {
  AddToSportStake,
  checkIfOddExistsInStake,
  RemoveFromTicket,
} from "../../Helpers/Helpers";

const EventSmallCard = (props) => {
  const consumer = useContext(GeneralContext);

  const resolveOddClassName = (stake, matchId, oddId) => {
    if (stake != null) {
      let xxx = stake.eventsArray.split(",");
      let yyy = stake.codesArray.split(",");
      let idx = xxx.indexOf(matchId.toString());
      if (idx != -1) {
        if (yyy[idx] === oddId.toString()) {
          return "event_small_card_wrap_selected";
        } else return "event_small_card_wrap";
      } else return "event_small_card_wrap";
    } else return "event_small_card_wrap";
  };

  return (
    <div className="event_small_card_root">
      <div className="event_small_card_details">
        <div className="event_small_details_match">
          {props.event.homeTeam} VS {props.event.awayTeam}
        </div>
        <div className="event_small_details_date">
          {props.event.abbrDate} {props.event.abbrHour}
        </div>
      </div>
      <div className="event_small_card_odds_wrap">
        {props.event.Odds.map((elem, key) => {
          return (
            <div className="event_small_card_odd" key={key}>
              <div
                className={resolveOddClassName(
                  consumer.spStake[0],
                  props.event.matchID,
                  elem.id
                )}
                onClick={() => {
                  if (
                    !checkIfOddExistsInStake(
                      consumer.spStake[0],
                      props.event.matchID,
                      elem.id
                    )
                  ) {
                    AddToSportStake(
                      consumer.spStake[0],
                      consumer.spStake[1],
                      consumer.combArray[0].join(","),
                      consumer.serial[0],
                      consumer.ticketStake[0],
                      props.event.matchID,
                      elem.id,
                      ""
                    );
                  } else
                    RemoveFromTicket(
                      consumer.spStake[0],
                      consumer.spStake[1],
                      props.event.matchID
                    );
                }}
              >
                <span className="mobile_odd_symbol_helper">{elem.symbol}</span>
                <span>{elem.value}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EventSmallCard;
