import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "../../App";
import { convertUtcToSingleRowTime } from "../../Helpers/Helpers";
import LotoPlayTable from "./LotoPlayTable";

const LotoContentSingleItem = (props) => {
  const consumer = useContext(GeneralContext);

  const [clockRough, setClockRough] = useState(null);
  const [clock, setClock] = useState(null);
  useEffect(() => {
    const yyy = setInterval(() => {
      setClockRough(new Date(props.elem.date) - new Date());
    }, 1000);
  }, []);
  useEffect(() => {
    if (clockRough) {
      var minutes = Math.floor(clockRough / 60000);
      var seconds = ((clockRough % 60000) / 1000).toFixed(0);

      if (seconds == 60) {
        setClock(minutes + 1 + ":00");
      } else {
        setClock(minutes + ":" + (seconds < 10 ? "0" : "") + seconds);
      }
    }
    // if (clockRough < 0) {
    //   consumer.lotoSelectedEvent[1](null);
    // }
  }, [clockRough]);

  return (
    clockRough > 0 && (
      <>
        <div className="loto_content_single_item_root">
          <div className="loto_single_item_date">
            {convertUtcToSingleRowTime(props.elem.date, "ro")}
          </div>
          <div className="loto_single_item_code">{props.elem.code}</div>
          <div className="loto_single_item_name">{props.elem.lotteryName}</div>
          <div className="loto_single_item_system">
            <div className="loto_single_item_system_draws">
              {props.elem.system.split("/")[0]}
            </div>
            <div className="loto_single_item_system_outOf">
              {props.elem.system.split("/")[1]}
            </div>
          </div>
          <div className="loto_single_item_odds">
            {props.elem.odds.split("|").map((el, i) => {
              return (
                <div key={i} className="loto_single_item_odd">
                  {el}
                </div>
              );
            })}
          </div>
        </div>

        {consumer.lotoSelectedEvent[0] === props.elem.id ? (
          <LotoPlayTable
            ballsNo={props.elem.system.split("/")[1]}
            clock={clock}
            elem={props.elem}
          />
        ) : null}
      </>
    )
  );
};

export default LotoContentSingleItem;
