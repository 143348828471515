import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { GeneralContext } from "../../App";

const MenuMaskSingleItem = (props) => {
  const history = useHistory();
  const consumer = useContext(GeneralContext);

  const resolveClassName = (id) => {
    if (id === props.activeItem)
      return "menumask_single_item_root menumask_is_active";
    return "menumask_single_item_root";
  };

  return (
    <div
      className={resolveClassName(props.id)}
      onClick={() => {
        props.setActiveItem(props.id);
        history.push("/sport");
        let xx = { id: props.id, name: props.title };
        consumer.activeMenuItemSport[1](xx);
      }}
    >
      <div className="menumask_single_item_icon">
        <img src={props.icon} className="menumask_single_item_icon_icon" />
      </div>
      <div className="menumask_single_item_name">{props.title}</div>
      <div className="menumask_single_item_events">{props.evNo}</div>
    </div>
  );
};

export default MenuMaskSingleItem;
