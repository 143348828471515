import React, { useContext, useEffect, useState } from "react";
import { GetLotoMenu } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import LotoMenuSingleItem from "./LotoMenuSingleItem";

const LotoMenu = (props) => {
  const [menuItems, setMenuItems] = useState(null);
  const consumer = useContext(GeneralContext);
  useEffect(() => {
    GetLotoMenu(consumer.dateSelector[0].indexOf(1), setMenuItems);
  }, []);

  useEffect(() => {
    GetLotoMenu(consumer.dateSelector[0].indexOf(1), setMenuItems);
  }, [consumer.dateSelector[0]]);

  return (
    <div className="loto_menu_root">
      {menuItems && (
        <div className="loto_menu_wrap">
          {menuItems.map((element, key) => {
            return (
              <div key={key}>
                <LotoMenuSingleItem
                  elem={element}
                  handleMobileMenuOpen={props.handleMobileMenuOpen}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default LotoMenu;
