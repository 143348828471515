import React from "react";
import { NavLink } from "react-router-dom";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer_root">
      <div className="footer_row_1">
        <a href="http://cloverbet.ro/regulament.pdf" target="_blank">
          Regulament
        </a>
        <a href="#" target="_blank">
          Agentii
        </a>
        <a href="http://www.anpc.gov.ro/" target="_blank">
          Protectia Consumatorului
        </a>
        <a href="/contact">Contact</a>
        <a href="#">
          <img src="./plus18.png" />
        </a>
        <a href="http://onjn.gov.ro/" target="_blank">
          <img src="./onjn.png" />
        </a>
      </div>
      <div className="footer_row_2">
        Licenta ONJN conform deciziei 2360 din 31.10.2018
      </div>
    </div>
  );
};

export default Footer;
