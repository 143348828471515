import React, { useContext, useEffect, useState } from "react";
import { GetMenuItems, GetOfferBySport } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import { resolveIconForSingleRow } from "../../Helpers/Helpers";
import MenuSingleItem from "./MenuSingleItem";

const Menu = (props) => {
  const consumer = useContext(GeneralContext);

  //Hook used to store all events for top20 querry. Top20 has the sportId=0
  const [popularItems, setPopularItems] = useState(null);

  //UseEffect used to get the offer for the top20 events on initialization
  useEffect(() => {
    GetOfferBySport(0, 0, setPopularItems);
  }, []);

  const [menuItems, setMenuItems] = useState(null);
  useEffect(() => {
    GetMenuItems(0, setMenuItems);
  }, []);

  useEffect(() => {
    GetOfferBySport(0, consumer.dateSelector[0].indexOf(1), setPopularItems);
    GetMenuItems(consumer.dateSelector[0].indexOf(1), setMenuItems);
  }, [consumer.dateSelector[0]]);

  //Function used to display first 3 items from @popularItems variable
  const renderPopularItems = () => {
    return popularItems.map((element, key) => {
      if (key < 3) {
        return (
          <div
            className="menu_popular_list_item"
            onClick={() => {
              consumer.activeSubmenuItemSport[1](element.leagueID);
              props.handleMobileMenuOpen && props.handleMobileMenuOpen();
            }}
            key={key}
          >
            {element.leagueName}
          </div>
        );
      }
    });
  };

  return (
    menuItems && (
      <div className="menu_root">
        <div
          className="menu_top20"
          onClick={() => {
            consumer.activeMenuItemSport[1]({
              id: 0,
              name: "Top 20 Evenimente",
            });
            props.handleMobileMenuOpen && props.handleMobileMenuOpen();
          }}
        >
          Top20 Evenimente
        </div>
        {popularItems && (
          <div className="menu_popular">
            <div className="pupulare_span">POPULARE</div>
            <div className="menu_popular_list">{renderPopularItems()}</div>
          </div>
        )}
        <div className="menu_menu">
          <MenuSingleItem
            title="TOATE SPORTURILE"
            icon="./icons/all.png"
            setActiveItem={consumer.activeMenuItemSport[1]}
            activeItem={consumer.activeMenuItemSport[0]}
            id={-1}
            handleMobileMenuOpen={props.handleMobileMenuOpen}
          />
          {menuItems.map((element, key) => {
            if (element.sportID != 0) {
              if (element.sportID == 85)
                return (
                  <div key={key}>
                    <MenuSingleItem
                      title="CLOVER COTA"
                      icon={resolveIconForSingleRow(element.sportID)}
                      evNo={element.betsNo}
                      setActiveItem={consumer.activeMenuItemSport[1]}
                      activeItem={consumer.activeMenuItemSport[0]}
                      id={element.sportID}
                      handleMobileMenuOpen={props.handleMobileMenuOpen}
                    />
                  </div>
                );
              if (element.sportID === 86)
                return (
                  <div key={key}>
                    <MenuSingleItem
                      title="EXTRA COTA"
                      icon={resolveIconForSingleRow(element.sportID)}
                      evNo={element.betsNo}
                      setActiveItem={consumer.activeMenuItemSport[1]}
                      activeItem={consumer.activeMenuItemSport[0]}
                      id={element.sportID}
                      handleMobileMenuOpen={props.handleMobileMenuOpen}
                    />
                  </div>
                );
              return (
                <div key={key}>
                  <MenuSingleItem
                    title={element.sportName}
                    icon={resolveIconForSingleRow(element.sportID)}
                    evNo={element.betsNo}
                    setActiveItem={consumer.activeMenuItemSport[1]}
                    activeItem={consumer.activeMenuItemSport[0]}
                    id={element.sportID}
                    handleMobileMenuOpen={props.handleMobileMenuOpen}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    )
  );
};

export default Menu;
