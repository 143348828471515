import React from "react";

const VirtualeKeno = () => {
  return (
    <div className="virtuale_six_root">
      <div className="virtuale_six_header">CLOVER KENO</div>
      <div className="virtuale_six_content">
        <div className="virtuale_six_image">
          <img
            src="./assets/clover_keno.jpg"
            className="virtuale_six_image_img"
          />
        </div>
        <div className="virtuale_six_text">
          <p>
            CloverKeno este un pariu pe numere in cota fixa pe evenimente
            virtuale.Acestea sunt redate concomitent si pot fi urmarite in toate
            agentiile CloverBet in care acest pariu este disponibil.
          </p>
          <p>
            Evenimentele se desfasoara sub forma de extrageri,astfel dintr-un
            total de 80 de numere se extrag 20. Numerele se extrag pe rand si
            sunt afisate pe ecranul televizoarelor.
          </p>
          <p>
            Extragerile se realizeaza o data la 4 minute si 30 de secunde,pe
            toata durata programului de lucru a agentiei. Desfasurarea efectiva
            a fiecarei extrageri si afisarea rezultatelor dureaza 1 minut si 30
            de secunde. În intervalul urmator de timp, pe ecranul televizoarelor
            sunt prezentate informații utile despre posibilitatile de pariere
            CloverKeno.
          </p>
          <p>
            Costul minim al unui bilet este de 2 lei, iar suma maxima care se
            poate paria pe un singur bilet este de 100 de lei.
          </p>
          <h3>Se alege 1 numar pe bilet</h3>
          <p>&#9830; 1 numar castigator =&#62; cota este 3.5</p>
          <h3>Se aleg 2 numere pe bilet</h3>
          <p>&#9830; 1 numar castigator =&#62; cota este 1</p>
          <p>&#9830; 2 numere castigatoare =&#62; cota este 8</p>
          <h3>Se aleg 3 numere pe bilet</h3>
          <p>&#9830; 2 numere castigatoare =&#62; cota este 3</p>
          <p>&#9830; 3 numere castigatoare =&#62; cota este 30</p>
          <h3>Se aleg 4 numere pe bilet</h3>
          <p>&#9830; 2 numere castigatoare =&#62; cota este 1</p>
          <p>&#9830; 3 numere castigatoare =&#62; cota este 10</p>
          <p>&#9830; 4 numere castigatoare =&#62; cota este 60</p>
          <h3>Se aleg 5 numere pe bilet</h3>
          <p>&#9830; 2 numere castigatoare =&#62; cota este 1</p>
          <p>&#9830; 3 numere castigatoare =&#62; cota este 3</p>
          <p>&#9830; 4 numere castigatoare =&#62; cota este 20</p>
          <p>&#9830; 5 numere castigatoare =&#62; cota este 150</p>
          <h3>Se aleg 6 numere pe bilet</h3>
          <p>&#9830; 3 numere castigatoare =&#62; cota este 2</p>
          <p>&#9830; 4 numere castigatoare =&#62; cota este 15</p>
          <p>&#9830; 5 numere castigatoare =&#62; cota este 60</p>
          <p>&#9830; 6 numere castigatoare =&#62; cota este 500</p>
          <h3>Se aleg 7 numere pe bilet</h3>
          <p>niciun numar castigator =&#62; cota este 1</p>
          <p>&#9830; 3 numere castigatoare =&#62; cota este 2</p>
          <p>&#9830; 4 numere castigatoare =&#62; cota este 4</p>
          <p>&#9830; 5 numere castigatoare =&#62; cota este 20</p>
          <p>&#9830; 6 numere castigatoare =&#62; cota este 80</p>
          <p>&#9830; 7 numere castigatoare =&#62; cota este 1000</p>
          <h3>Se aleg 8 numere pe bilet</h3>
          <p>&#9830; niciun numar castigator =&#62; cota este 1</p>
          <p>&#9830; 4 numere castigatoare =&#62; cota este 5</p>
          <p>&#9830; 5 numere castigatoare =&#62; cota este 15</p>
          <p>&#9830; 6 numere castigatoare =&#62; cota este 50</p>
          <p>&#9830; 7 numere castigatoare =&#62; cota este 200</p>
          <p>&#9830; 8 numere castigatoare =&#62; cota este 1800</p>
          <h3>Se aleg 9 numere pe bilet</h3>
          <p>&#9830; niciun numar castigator =&#62; cota este 1</p>
          <p>&#9830; 4 numere castigatoare =&#62; cota este 2</p>
          <p>&#9830; 5 numere castigatoare =&#62; cota este 10</p>
          <p>&#9830; 6 numere castigatoare =&#62; cota este 25</p>
          <p>&#9830; 7 numere castigatoare =&#62; cota este 150</p>
          <p>&#9830; 8 numere castigatoare =&#62; cota este 2000</p>
          <p>&#9830; 9 numere castigatoare =&#62; cota este 3000</p>
          <h3>Se aleg 10 numere pe bilet</h3>
          <p>&#9830; niciun numar castigator =&#62; cota este 2</p>
          <p>&#9830; 5 numere castigatoare =&#62; cota este 5</p>
          <p>&#9830; 6 numere castigatoare =&#62; cota este 20</p>
          <p>&#9830; 7 numere castigatoare =&#62; cota este 100</p>
          <p>&#9830; 8 numere castigatoare =&#62; cota este 1000</p>
          <p>&#9830; 9 numere castigatoare =&#62; cota este 3000</p>
          <p>&#9830; 10 numere castigatoare =&#62; cota este 5000</p>
          <p>
            In cazul in care se intrerupe transmiterea jocului sau apar diferite
            probleme tehnice si rezultatul rundei nu este cunoscut,biletul
            primeste cota 1.
          </p>
          <p>Biletele inregistrate nu se pot storna.</p>
        </div>
      </div>
    </div>
  );
};

export default VirtualeKeno;
