import React, { useContext } from "react";
import { GeneralContext } from "../../App";

const LotoMenuSingleItem = (props) => {
  const consumer = useContext(GeneralContext);

  const createFlag = (countryCode) => {
    var toReturn = "flags/" + countryCode?.toLowerCase() + ".png";
    return toReturn;
  };

  const resolveActiveClassName = (idx) => {
    if (idx === consumer.lotoActiveItem[0].id)
      return "loto_menu_sg_item_root logo_sg_active";
    return "loto_menu_sg_item_root";
  };

  return (
    <div
      className={resolveActiveClassName(props.elem.id)}
      onClick={() => {
        let xx = {
          id: props.elem.id,
          max: props.elem.maxBetNumbers,
          maxBet: props.elem.maxSystem,
          name: props.elem.name,
        };
        consumer.lotoActiveItem[1](xx);
        props.handleMobileMenuOpen();
      }}
    >
      <div className="loto_sg_item_header_left">
        <img
          src={createFlag(props.elem.imgKey)}
          className="loto_sg_item_left_icon"
        />
        <div className="loto_sg_item_left_label">{props.elem.system}</div>
      </div>
      <div className="loto_sg_item_header_right">
        <div className="loto_sg_item_right_name">{props.elem.name}</div>
        <div className="loto_sg_item_right_desc">
          {props.elem.info.split("/")[0]}
        </div>
      </div>
    </div>
  );
};

export default LotoMenuSingleItem;
