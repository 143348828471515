import React, { useContext, useEffect, useState } from "react";
import { GetOfferByLeague, GetOfferBySport } from "../../API/ApiManager";
import { GeneralContext } from "../../App";
import { resolveIconForSingleRow } from "../../Helpers/Helpers";
import DateSelector from "./DateSelector";
import LeagueCard from "./LeagueCard";

const SportContent = (props) => {
  const consumer = useContext(GeneralContext);

  const [events, setEvents] = useState(null);

  // useEffect(() => {
  //   GetOfferBySport(
  //     consumer.activeMenuItemSport[0].id,
  //     consumer.dateSelector[0].indexOf(1),
  //     setEvents
  //   );
  // }, []);

  useEffect(() => {
    GetOfferBySport(
      consumer.activeMenuItemSport[0].id,
      consumer.dateSelector[0].indexOf(1),
      setEvents
    );
  }, [consumer.activeMenuItemSport[0]]);

  useEffect(() => {
    GetOfferByLeague(
      consumer.activeSubmenuItemSport[0],
      consumer.dateSelector[0].indexOf(1),
      setEvents
    );
  }, [consumer.activeSubmenuItemSport[0]]);

  useEffect(() => {
    GetOfferBySport(
      consumer.activeMenuItemSport[0].id,
      consumer.dateSelector[0].indexOf(1),
      setEvents
    );
  }, [consumer.dateSelector[0]]);

  const [restrictedRaw, setRestrictedRaw] = useState(null);
  const [restricted, setRestricted] = useState(null);
  useEffect(() => {
    consumer.dateSelector[1]([1, 0, 0, 0, 0, 0, 0, 0]);
    GetOfferBySport(86, consumer.dateSelector[0].indexOf(1), setRestrictedRaw);
    GetOfferBySport(
      consumer.activeMenuItemSport[0].id,
      consumer.dateSelector[0].indexOf(1),
      setEvents
    );
  }, []);

  useEffect(() => {
    if (restrictedRaw != null && restrictedRaw.length > 0) {
      let xx = restrictedRaw[0].Events;
      let aux = [];
      xx.map((el, i) => {
        aux.push(el.matchID);
      });

      setRestricted(aux);
    }
  }, [restrictedRaw]);

  return (
    <div className="sport_content_root">
      <div className="sport_content_selector">
        <DateSelector />
      </div>
      {events && (
        <div className="sport_content_content">
          {events.map((element, key) => {
            return (
              <div key={key}>
                <LeagueCard
                  icon={resolveIconForSingleRow(element.sportID)}
                  title={element.leagueName}
                  label={element.sportName}
                  events={element.Events}
                  restricted={restricted}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default SportContent;
