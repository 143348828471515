import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "../../App";
import { getCurrentDaysArr, getCurrentDaysArrRes } from "../../Helpers/Helpers";
import "./Results.css";
import ResultsContent from "./ResultsContent";
import ResultsDateSelector from "./ResultsDateSelector";
import ResultsMenu from "./ResultsMenu";

const Results = (props) => {
  const consumer = useContext(GeneralContext);

  const [stuffSelector, setStuffSelector] = useState([1, 0]);
  const toggleStuffSelector = (idx) => {
    let aux = [0, 0];
    aux[idx] = 1;
    setStuffSelector(aux);
  };
  const resolveStuffSelecotrClass = (idx) => {
    let xx = stuffSelector.indexOf(1);
    if (xx === idx) return "results_content_stuff_sel_sp stuff_sel_active";
    return "results_content_stuff_sel_sp";
  };
  const [shortDays, setShortDays] = useState(null);

  useEffect(() => {
    setShortDays(getCurrentDaysArrRes("RO-ro"));
  }, []);
  const formatDateSelector = (index) => {
    return (
      shortDays && (
        <span style={{ fontWeight: 600 }}>
          {shortDays.shortDays[index]}{" "}
          <span style={{ fontWeight: 300 }}>
            {shortDays.dayNmonth[index].day +
              "." +
              shortDays.dayNmonth[index].month}
          </span>
        </span>
      )
    );
  };

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const handleMobileMenuOpen = () => {
    setDateMenuOpen(false);
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const [dateMenuOpen, setDateMenuOpen] = useState(false);
  const handleDateMenuOpen = () => {
    setMobileMenuOpen(false);
    setDateMenuOpen(!dateMenuOpen);
    consumer.activeResultsName[1]("TOATE SPORTURILE");
    consumer.activeResultsMenuItem[1](0);
  };
  const [mobileTicketOpen, setMobileTicketOpen] = useState(false);
  const handleMobileTicketOpen = () => setMobileTicketOpen(!mobileTicketOpen);

  return (
    <>
      <div className="rooooot">
        <div className="results_root">
          <div className="results_menu">
            <ResultsMenu />
          </div>
          <div className="results_content">
            <div className="results_content_stuff_selector">
              <div
                className={resolveStuffSelecotrClass(0)}
                onClick={() => toggleStuffSelector(0)}
              >
                SPORT
              </div>
              <div
                className={resolveStuffSelecotrClass(1)}
                onClick={() => toggleStuffSelector(1)}
              >
                LOTO
              </div>
            </div>
            <ResultsContent />
          </div>
          <div className="results_selector">
            <ResultsDateSelector />
          </div>
        </div>
      </div>

      <div className="mobile_results_root">
        <div className="mobile_sport_selectors">
          <div
            className="mobile_sport_selected_sport"
            onClick={() => handleMobileMenuOpen()}
          >
            {consumer.activeResultsName[0]}
          </div>
          <div
            className="mobile_sport_selected_date"
            onClick={() => handleDateMenuOpen()}
          >
            {consumer.dateSelectorRes[0].indexOf(1) === 0
              ? "Astazi"
              : formatDateSelector(consumer.dateSelectorRes[0].indexOf(1))}
          </div>
        </div>

        <div className="results_content_stuff_selector">
          <div
            className={resolveStuffSelecotrClass(0)}
            onClick={() => toggleStuffSelector(0)}
          >
            SPORT
          </div>
          <div
            className={resolveStuffSelecotrClass(1)}
            onClick={() => toggleStuffSelector(1)}
          >
            LOTO
          </div>
        </div>

        <div className="mobile_results_content">
          <ResultsContent />
        </div>
      </div>

      {mobileMenuOpen && (
        <div className="mobile_frontpage_menu_mask">
          <ResultsMenu handleMobileMenuOpen={handleMobileMenuOpen} />
        </div>
      )}
      {dateMenuOpen && (
        <div className="mobile_frontpage_menu_mask">
          <ResultsDateSelector handleDateMenuOpen={handleDateMenuOpen} />
        </div>
      )}
    </>
  );
};

export default Results;
