import React from "react";

const VirtualeRuleta = () => {
  return (
    <div className="virtuale_six_root">
      <div className="virtuale_six_header">CLOVER RULETA</div>
      <div className="virtuale_six_content">
        <div className="virtuale_six_image">
          <img
            src="./assets/clover_rlt.jpg"
            className="virtuale_six_image_img"
          />
        </div>
        <div className="virtuale_six_text">
          <p>
            Ruleta este un pariu pe numere in cota fix pe evenimente virtuale.
          </p>
          <p>
            Acestea sunt afisate concomitent si pot fi urmarite in toate
            agentiile Clover Bet in care se poate plasa acest tip de pariu.
          </p>
          <p>
            Evenimentele se bazeaza pe o roata adancita in mijloc iar pe margine
            niste locasuri, fiecare locas fiind asociat cu un numar. In momentul
            pornirii evenimentului o bila se invarte pe margine pana cand
            aceasta se va oprii si va ajunge in unul din locasurile asociate
            unui numar iar acela va fi numarul castigator al evenimentului.
          </p>
          <p>
            Pentru a plasa pariul jucatorul dispune de o tabla de pariere cu
            numere de la 0 la 36.
          </p>
          <p>
            Cand un numar este declarant castigator toate mizele plasate
            direct(STRAIGHT) pe acesta vor primii o cota de 36. Pot fi declarate
            mai multe tipuri de pariuri castigatoare pe un singur eveniment.
          </p>
          <h3>Tipuri de pariuri</h3>
          <h3>Parierea STRAIGHT (directa) COTA 36</h3>
          <p>se pariaza direct pe un numar de la 0 la 36</p>
          <h3>Parierea SPLIT (impartita) COTA 18</h3>
          <p>se pariaza pe 2 numere alaturate pe tabla de pariere</p>
          <h3>Parierea STREET - COTA 12</h3>
          <p>se pariaza pe 3 numere pe aceeasi linie</p>
          <h3>Parierea CORNER (square bet- patrat) COTA 9</h3>
          <p>se pariaza pe 4 numere de forma unui patrat</p>
          <h3>Parierea LINE (Linie) COTA 6</h3>
          <p>se pariaza pe 6 numere aflate pe doua linii alaturate</p>
          <h3>Parierea ROSU/NEGRU - COTA 2</h3>
          <p>se pariaza pe culoare numarului castigator</p>
          <h3>Parierea Par/Impar - COTA 2</h3>
          <p>se pariaza pe numerele de la 1-36 par sau impar</p>
          <h3>Parierea Low/High (mic/mare) COTA 2</h3>
          <p>
            se pariaza pe tipul numarului castigator. Valoare mica 1-18 sau
            valoare mare 19-36
          </p>
          <h3>Parierea DOZEN (duzina) COTA 3</h3>
          <p>se pariaza pe trei grupe a cate 12 numere – 1-12, 13-24, 25-36</p>
          <h3>Parierea COLUMN (coloana) COTA 3</h3>
          <p>se pariaza pe una din cele 3 coloane de pe tabla de pariere</p>
        </div>
      </div>
    </div>
  );
};

export default VirtualeRuleta;
