import React from "react";

const VirtualeCanini = () => {
  return (
    <div className="virtuale_six_root">
      <div className="virtuale_six_header">CLOVER KENO</div>
      <div className="virtuale_six_content">
        <div className="virtuale_six_image">
          <img
            src="./assets/clover_dogs.png"
            className="virtuale_six_image_img"
          />
        </div>
        <div className="virtuale_six_text">
          <p>
            Pariurile pe curse de caini sunt realizate de catre client pe
            evenimente ce constau in curse de caini preinregistrate, care se
            difuzeaza simultan in toate agentiile, transmisia curselor fiind
            realizata de un server independent,la care organizatorul nu are
            acces.
          </p>
          <p>
            În cursă aleargă 6 câini identificaţi prin numere de la 1 la 6,
            cărora le corespund culori exact în ordinea următoare: roşu,
            albastru, alb, negru, portocaliu şi alb cu dungi negre. La
            terminarea cursei, sunt desemnaţi primii doi clasaţi exact în
            ordinea trecerii liniei de sosire: primul, respectiv al doilea.
          </p>
          <p>Cursele rulează la fiecare 5 minute.</p>
          <p>
            Timp de 4 minute se prezintă cotele pentru cursa următoare, iar în
            partea de jos a ecranului se afişează rezultatele ultimelor 3 curse
            - indicând primii doi clasaţi. Simultan, sunt indicate cotele pentru
            următoarea cursă atât pentru pariul simplu (câştigător) cât şi
            pentru pariul dublu (primii doi clasaţi). În acestă perioadă se pot
            efectua bilete, pariurile închizându-se cu 2 secunde înainte de
            startul cursei. Cursa dureză 40 de secunde şi este urmată de
            prezentarea câştigătorilor timp de 20 de secunde.
          </p>
          <p>
            Jucătorul poate paria pe câştigător (pariul simplu) sau pe primele
            două poziţii (pariul dublu).
          </p>
          <p>
            O altă posibilitate de pariere este pariul sistem care îţi permite
            să pariezi pe unul sau mai mulţi câini castigatori ai primului loc
            (pe 1,2,3,4,5 sau chiar 6 câini din cei 6 care participă la cursă)
            cât şi pe unul sau mai mulţi câini ai primelor două locuri
            (1,2,3,4,5 sau chiar 6 câini din cei 6 care participă la cursă).
          </p>
          <h3>Tipuri de pariuri</h3>
          <p>CASTIGATOR(WINNER)</p>
          <p>Se indica daca cainele va fi castigator,sau nu</p>
          <h3>IN PRIMII 2</h3>
          <p>
            Se indica daca cainele se va plasa in primii doi,indiferent ca va
            iesi pe 1 sau pe 2
          </p>
          <h3>IN PRIMII 3</h3>
          <p>
            Se indica daca cainele se va plasa in primii trei,indiferent ca va
            iesi pe 1,2 sau 3
          </p>
          <h3>WIN & S/P</h3>
          <p>
            Se indica numarul cainelui castigator si daca suma primilor trei va
            fi Sub 10.5 sau Peste 10.5
          </p>
          <h3>PAR/IMPAR</h3>
          <p>
            Par:cainele castigator va fi 1,2 sau 3 respectiv Impar:cainele
            castigator va fi 1,3 sau 5
          </p>
          <h3>MIC/MARE</h3>
          <p>
            Mic:cainele castigator va fi 1,2 sau 3 respectiv Mare:cainele
            castigator va fi 4,5 sau 6
          </p>
          <h3>SUMA PRIMILOR 3 CAINI</h3>
          <p>Suma primilor 3 caini va fi Sub 10.5 sau Peste 10.5</p>
          <h3>PRIMII 2 IN ORDINE EXACTA (EXACTA)</h3>
          <p>Se indica primii doi caini in ordine exacta</p>
          <h3>PRIMII 2 IN ORICE ORDINE (QUEINELLA)</h3>
          <p>Se indica primii doi caini in orice ordine ar termina cursa</p>
          <h3>TRIFECTA</h3>
          <p>Se indica primii trei caini in ordine exacta</p>
          <p>Exemple tipuri de pariuri</p>
          <h3>PARIUL SIMPLU</h3>
          <p>
            Pariezi pe cainele castigator: Câştigul se calculează prin
            înmulţirea cotei cu miza. Exemplu: Dacă mizezi 10 lei pe câinele cu
            numărul 3 (cota 7,0) şi aceasta va fi primul clasat, vei câştiga 70
            lei (7 x 10 lei).
          </p>
          <h3>PARIUL DUBLU</h3>
          <p>
            Pariezi pe câinii care ocupă primele două poziţii, exact în ordinea
            în care trec linia de final. Câştigul se calculează prin înmulţirea
            cotei cu miza. Exemplu: Dacă mizezi 10 lei pe câinii cu numerele 3
            şi 5, iar aceştia vor fi primii la finalul cursei, exact în această
            ordine, atunci vei câştigă 484,1 lei (10 lei x 48,41).Pentru pariul
            dublu, cota se regăseşte la intersecţia coloanei reprezentată de
            câinele câştigător, cu linia aferentă câinelui de pe a doua poziţie.
          </p>
          <h3>PARIUL COMBINAT</h3>
          <p>
            Pariezi pe mai mulţi posibili câştigători, la simplu sau la dublu.
            De această dată, câştigul se calculează prin înmulţirea cotei cu
            miza pe combinaţie. Miza pe combinaţie este rezultatul împărţirii
            mizei pe bilet la numărul de combinaţii. Pentru exemplele următoare
            vom avea în vedere rezultatele cursei indicate de tabela de mai jos
            :
          </p>
          <p>
            • la simplu, poţi paria pe 2 sau mai mulţi câini şi câştigi dacă
            unul dintre câinii selectaţi termină cursa pe locul 1. Exemplu: Dacă
            mizezi 10 lei pe câinii 1, 2, 3, 4 şi 6 posibili câştigători, iar
            câinele cu numărul 6 câştigă cursa (cota 3,5), atunci câştigul tău
            va fi 2 lei X 3,5 = 7 lei. (miza totală pe bilet - 10 lei se împarte
            la numărul de combinaţii - 5, rezultând o miza pe combinaţie de 2
            lei).
          </p>
          <p>
            • la dublu, poţi paria pe o combinaţie formată din primii 2 câini
            clasaţi (locurile 1 şi 2), cu mai multe posibilităţi pentru fiecare
            loc în parte Exemplu: Dacă mizezi 60 lei pe câinii cu numerele 1, 3,
            6 ca posibili câştigători, iar pe câinii cu numerele 2 şi 5, ca
            ocupanţi ai locului al doilea, atunci ai 6 combinaţii posibil
            câştigătoare. În cazul în care câinele 6 câştigă cursa iar câinele 5
            ocupă locul al 2-lea, atunci câştigul se va calcula astfel : 20,17
            (cota pariului) x 10 lei (miza totală/ numarul de combinaţii) =
            201,7 (câştigul).
          </p>
        </div>
      </div>
    </div>
  );
};

export default VirtualeCanini;
