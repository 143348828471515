import React from "react";
import s from "./Info.module.css";

const Info = (props) => {
  return (
    <div className={s.info}>
      <div className={s.infoText}>
        <h2 className={s.hdoi}>
          {props.response[2].jackpotCode.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className={s.infoNumber}>
          {props.response !== null ? props.response[2].jackpotValue : "RON"}

          <span> RON</span>
        </p>
      </div>
      <div className={s.infoText}>
        <h2 className={s.hdoi}>
          {props.response[0].jackpotCode.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className={s.infoNumber}>
          {props.response !== null ? props.response[0].jackpotValue : "RON"}

          <span> RON</span>
        </p>
      </div>
      <div className={s.infoText}>
        <h2 className={s.hdoi}>
          {props.response[4].jackpotCode.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className={s.infoNumber}>
          {props.response !== null ? props.response[4].jackpotValue : "RON"}

          <span> RON</span>
        </p>
      </div>
      <div className={s.infoText}>
        <h2 className={s.hdoi}>
          {props.response[1].jackpotCode.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className={s.infoNumber}>
          {props.response !== null ? props.response[1].jackpotValue : "RON"}

          <span> RON</span>
        </p>
      </div>
      <div className={s.infoText}>
        <h2 className={s.hdoi}>
          {props.response[3].jackpotCode.toString().replace("_", " ")}
        </h2>
        {/* <h2>PLACEHOLDER</h2> */}
        <p className={s.infoNumber}>
          {props.response !== null ? props.response[3].jackpotValue : "RON"}

          <span> RON</span>
        </p>
      </div>
    </div>
  );
};

export default Info;
