import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-elastic-carousel";
import s from "./Carousel.module.css";
import axios from "axios";
const CarouselComp = () => {
  const carouselRef = useRef(null);
  let resetTimeout;

  const [rest, setRest] = useState(null);
  const [allImgs, setAllImgs] = useState([]);
  const [fIdx, setFidx] = useState(null);

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}${month}${day}`;
  };

  useEffect(() => {
    let dt = new Date();
    let formated = formatDate(dt);
    async function smth() {
      setAllImgs([]);
      for (let i = 0; i <= 9; i++) {
        let apiRes = null;
        let url =
          process.env.PUBLIC_URL +
          "/images/" +
          formated +
          "_slide_0" +
          i +
          ".jpg";
        try {
          apiRes = await axios.get(
            process.env.PUBLIC_URL +
              "/images/" +
              formated +
              "_slide_0" +
              i +
              ".jpg"
          );
        } catch (err) {
          apiRes = err;
        } finally {
          setRest(apiRes);
        }
      }
    }
    smth();
  }, []);

  useEffect(() => {
    if (rest != null) {
      if (!rest?.message) {
        let aux = [...allImgs];
        aux.push(rest);
        setAllImgs(aux);
        setFidx(allImgs.length);
      }
    }
  }, [rest]);

  useEffect(() => {
    if (fIdx === null) {
      let aux = [];
      let url = process.env.PUBLIC_URL + "/clover_banner.jpg";
      axios.get(url).then((resp) => aux.push(resp));
      setAllImgs(aux);
    }
  }, [fIdx]);

  return (
    allImgs && (
      <div className={s.carouselContainer}>
        <Carousel
          className={s.carousel}
          showArrows={false}
          pagination={false}
          itemsToShow={1}
          ref={carouselRef}
          itemsToScroll={1}
          enableAutoPlay={true}
          autoPlaySpeed={3000}
          onNextEnd={({ index }) => {
            clearTimeout(resetTimeout);
            if (index + 1 === allImgs.length) {
              if (carouselRef.current.goTo) {
                resetTimeout = setTimeout(() => {
                  if (carouselRef.current.goTo) {
                    carouselRef.current.goTo(0);
                  }
                }, 3000);
              }
            }
          }}
        >
          {allImgs.map((item, key) => {
            return (
              <img
                src={item.config.url}
                alt=""
                className={s.carouselImg}
                key={key}
              />
            );
          })}
        </Carousel>
      </div>
    )
  );
};

export default CarouselComp;
