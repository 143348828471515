import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "../../App";

const SubMenuItems = (props) => {
  const [activeSubItem, setActiveSubItem] = useState(null);

  const consumer = useContext(GeneralContext);

  const renderNameTooLong = (name) => {
    let xxx = name.toString();
    if (xxx.length > 33) {
      return xxx.substring(0, 33) + "...";
    }
    return xxx;
  };

  const resolveClassName = (id) => {
    if (id === activeSubItem) return "submenu_single_item submenu_is_active";
    return "submenu_single_item";
  };

  return (
    <div className="submenu_root">
      {props.items.map((element, key) => {
        return (
          <div
            className={resolveClassName(element.leagueID)}
            key={key}
            onClick={() => {
              consumer.activeSubmenuItemSport[1](element.leagueID);
              setActiveSubItem(element.leagueID);
              props.handleMobileMenuOpen && props.handleMobileMenuOpen();
            }}
          >
            {renderNameTooLong(element.leagueName)}
          </div>
        );
      })}
    </div>
  );
};

export default SubMenuItems;
