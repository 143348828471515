import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navigation.css";

const Navigation = () => {
  const [menuActive, setMenuActive] = useState([1, 0, 0, 0, 0, 0]);
  const toggleActiveStuff = (idx) => {
    let aux = [0, 0, 0, 0, 0, 0];
    aux[idx] = 1;
    setMenuActive(aux);
  };

  const resolveClassName = (index) => {
    let xx = menuActive.indexOf(1);
    if (xx === index) return "navigation_menu_item menu_is_active";
    return "navigation_menu_item";
  };

  //Mobile version hooks
  const [toggleMenuOpen, setToggleMenuOpen] = useState(false);
  const handleToggleMenuOpen = () => setToggleMenuOpen(!toggleMenuOpen);

  const resolveMobileClassName = (idx) => {
    let xx = menuActive.indexOf(1);
    if (xx === idx) return "mobile_opened_menu_item menu_is_active_mobile";
    return "mobile_opened_menu_item";
  };

  return (
    <>
      <div className="navigation_root">
        <div className="navigation_logo">
          <div
            className="navigation_logo_wrap"
            onClick={() => toggleActiveStuff(0)}
          >
            <NavLink to="/">
              <img src="./cloverlogo.png" className="clover_logo" />
            </NavLink>
          </div>
        </div>
        <div className="navigation_menu">
          <NavLink to="/sport">
            <div
              className={resolveClassName(1)}
              onClick={() => toggleActiveStuff(1)}
            >
              SPORT
            </div>
          </NavLink>
          <NavLink to="/loto">
            <div
              className={resolveClassName(2)}
              onClick={() => toggleActiveStuff(2)}
            >
              LOTO
            </div>
          </NavLink>
          <NavLink to="/rezultate">
            <div
              className={resolveClassName(3)}
              onClick={() => toggleActiveStuff(3)}
            >
              REZULTATE
            </div>
          </NavLink>
          <NavLink to="/virtuale">
            <div
              className={resolveClassName(4)}
              onClick={() => toggleActiveStuff(4)}
            >
              VIRTUALE
            </div>
          </NavLink>
          <NavLink to="/contact">
            <div
              className={resolveClassName(5)}
              onClick={() => toggleActiveStuff(5)}
            >
              CONTACT
            </div>
          </NavLink>
        </div>
        <div className="navigation_stuff" style={{ textAlign: "right" }}>
          {/* --@@ */}
        </div>
      </div>

      <div className="mobile_version_root">
        <div
          className="mobile_version_burger_menu"
          onClick={() => {
            handleToggleMenuOpen();
            toggleActiveStuff(0);
          }}
        >
          <img
            src="./menu.png"
            width="24px"
            height="24px"
            className="mobile_burger_img"
          />
        </div>
        <div className="mobile_version_logo">
          <div className="mobile_version_logo_wrap">
            <NavLink to="/">
              <img src="./cloverlogo.png" />
            </NavLink>
          </div>
        </div>
      </div>

      {toggleMenuOpen && (
        <div className="mobile_version_opened_menu">
          <div
            className={resolveMobileClassName(1)}
            onClick={() => {
              toggleActiveStuff(1);
              handleToggleMenuOpen();
            }}
          >
            <NavLink to="/sport">
              <span>SPORT</span>
            </NavLink>
          </div>
          <div
            className={resolveMobileClassName(2)}
            onClick={() => {
              handleToggleMenuOpen();
              toggleActiveStuff(2);
            }}
          >
            <NavLink to="/loto">
              <span>LOTO</span>
            </NavLink>
          </div>
          <div
            className={resolveMobileClassName(3)}
            onClick={() => {
              handleToggleMenuOpen();
              toggleActiveStuff(3);
            }}
          >
            <NavLink to="/rezultate">
              <span>REZULTATE</span>
            </NavLink>
          </div>
          <div
            className={resolveMobileClassName(4)}
            onClick={() => {
              handleToggleMenuOpen();
              toggleActiveStuff(4);
            }}
          >
            <NavLink to="/virtuale">
              <span>VIRTUALE</span>
            </NavLink>
          </div>
          <div
            className={resolveMobileClassName(5) + " last_mobile_menu_item"}
            onClick={() => {
              handleToggleMenuOpen();
              toggleActiveStuff(5);
            }}
          >
            <NavLink to="/contact">
              <span>CONTACT</span>
            </NavLink>
          </div>

          <div
            className="mobile_opened_menu_item"
            onClick={() => {
              handleToggleMenuOpen();
              toggleActiveStuff(6);
            }}
          >
            <NavLink to="/mverify">
              <span>VERIFICARE TICKET</span>
            </NavLink>
          </div>
        </div>
      )}
    </>
  );
};

export default Navigation;
