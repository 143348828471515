import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { GetMenuItems, GetOfferBySport } from "../../API/ApiManager";
import { resolveIconForSingleRow } from "../../Helpers/Helpers";
import MenuMaskSingleItem from "./MenuMaskSingleItem";

const MenuMask = (props) => {
  const [menuItems, setMenuItems] = useState(null);
  const [popularItems, setPopularItems] = useState(null);
  const [activeItem, setActiveItem] = useState(null);

  useEffect(() => {
    GetMenuItems(0, setMenuItems);
    GetOfferBySport(0, 0, setPopularItems);
  }, []);

  const history = useHistory();

  // useEffect(() => {
  //   if (activeItem != null) {
  //     console.log(activeItem);
  //   }
  // }, [activeItem]);

  const renderPopularItems = () => {
    return popularItems.map((element, key) => {
      if (key < 3) {
        return (
          <div
            className="menumask_populare_list_item"
            onClick={() => history.push("/sport")}
            key={key}
          >
            {element.leagueName}
          </div>
        );
      }
    });
  };

  return (
    menuItems && (
      <div className="menumask_root">
        <div
          className="menumask_top20"
          onClick={() => {
            setActiveItem(0);
            localStorage.setItem("act", 0);
            history.push("/sport");
          }}
        >
          Top20 Evenimente
        </div>
        <div className="menumask_populare">
          <div className="pupulare_span">POPULARE</div>
          {popularItems && (
            <div className="menumask_populare_list">{renderPopularItems()}</div>
          )}
        </div>
        <div className="menumask_menu">
          <MenuMaskSingleItem
            title="TOATE SPORTURILE"
            icon="./icons/all.png"
            setActiveItem={setActiveItem}
            activeItem={activeItem}
            id={-1}
          />
          {menuItems.map((elem, key) => {
            if (elem.sportID != 0) {
              if (elem.sportID == 85)
                return (
                  <div key={key}>
                    <MenuMaskSingleItem
                      title="CLOVER COTA"
                      icon={resolveIconForSingleRow(elem.sportID)}
                      evNo={elem.betsNo}
                      setActiveItem={setActiveItem}
                      activeItem={activeItem}
                      id={elem.sportID}
                    />
                  </div>
                );
              if (elem.sportID === 86)
                return (
                  <div key={key}>
                    <MenuMaskSingleItem
                      title="EXTRA COTA"
                      icon={resolveIconForSingleRow(elem.sportID)}
                      evNo={elem.betsNo}
                      setActiveItem={setActiveItem}
                      activeItem={activeItem}
                      id={elem.sportID}
                    />
                  </div>
                );
              return (
                <div key={key}>
                  <MenuMaskSingleItem
                    title={elem.sportName}
                    icon={resolveIconForSingleRow(elem.sportID)}
                    evNo={elem.betsNo}
                    setActiveItem={setActiveItem}
                    activeItem={activeItem}
                    id={elem.sportID}
                  />
                </div>
              );
            }
          })}
        </div>
      </div>
    )
  );
};

export default MenuMask;
