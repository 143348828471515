import React, { useContext, useEffect, useState } from "react";
import { GeneralContext } from "../../App";
import {
  resolveIconForSingleRow,
  checkIfOddExistsInStake,
  AddToSportStake,
  RemoveFromTicket,
} from "../../Helpers/Helpers";
import { Slider } from "@mui/material";
const DetailedOdds = (props) => {
  const consumer = useContext(GeneralContext);

  const [clock, setClock] = useState(
    new Date(consumer.allOddsForEvent[0][0].matchDate)
  );

  const [activeArr, setActiveArr] = useState(
    new Array(consumer.allOddsForEvent[0].length).fill(0)
  );

  const [filterName, setFilterName] = useState("TOATE");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let aux = [...activeArr];
    aux[0] = 1;
    setActiveArr(aux);
  }, []);

  const resolveClassName = (idx) => {
    if (activeArr.indexOf(1) === idx) return "detailed_odds_pill pill_active";
    return "detailed_odds_pill";
  };

  const toggleActiveArr = (idx) => {
    let aux = [new Array(consumer.allOddsForEvent[0].length).fill(0)];
    aux[idx] = 1;
    setActiveArr(aux);
  };

  const [headerValues, setHeadetValues] = useState(null);
  useEffect(() => {
    setHeadetValues(consumer.allOddsForEvent[0][0]);
  }, []);

  const [oddContent, setOddContent] = useState(null);

  useEffect(() => {
    if (filterName === "TOATE") {
      let aux = [];
      consumer.allOddsForEvent[0].map((elem, key) => {
        if (key > 0) {
          aux.push(elem);
        }
      });
      setOddContent(aux);
    } else {
      consumer.allOddsForEvent[0].map((elem, key) => {
        if (elem.betName === filterName) {
          setOddContent([elem]);
        }
      });
    }
  }, [filterName]);

  const onClickOdd = (value, id) => {
    if (value > 1) {
      if (
        !checkIfOddExistsInStake(consumer.spStake[0], headerValues.matchId, id)
      ) {
        AddToSportStake(
          consumer.spStake[0],
          consumer.spStake[1],
          consumer.combArray[0].join(","),
          consumer.serial[0],
          consumer.ticketStake[0],
          headerValues.matchId,
          id,
          ""
        );
      } else {
        RemoveFromTicket(
          consumer.spStake[0],
          consumer.spStake[1],
          headerValues.matchId
        );
      }
    }
  };

  const resolveOddClassName = (stake, matchId, oddId) => {
    if (stake != null) {
      let xxx = stake.eventsArray.split(",");
      let yyy = stake.codesArray.split(",");
      let idx = xxx.indexOf(matchId.toString());
      if (idx != -1) {
        if (yyy[idx] === oddId.toString()) {
          return "market_wrapper_wrap_odd_selected";
        } else return "market_wrapper_wrap_odd";
      } else return "market_wrapper_wrap_odd";
    } else return "market_wrapper_wrap_odd";
  };

  const [slider, setSlider] = useState([1.01, 20]);
  const onSliderChange = (event, newVal) => {
    setSlider(newVal);
  };

  return (
    activeArr && (
      <div className="detailed_odds_root">
        {headerValues && (
          <div className="detailed_odds_header">
            <div
              className="detailed_odds_back"
              onClick={() => {
                consumer.allOddsForEvent[1](null);
              }}
            >
              <img src="./previous.png" className="detailed_odds_back_icon" />
              <span className="detailed_odds_back_span">INAPOI</span>
            </div>
            <div className="detailed_odds_info">
              <div className="detailed_odds_info_league">
                <img
                  src={resolveIconForSingleRow(headerValues.sportId)}
                  width="30px"
                  height="30px"
                />
                <div className="detailed_odds_info_league_span">
                  {headerValues.leagueName}
                </div>
              </div>
              <div className="detailed_odds_info_match">
                <div className="detailed_odds_info_match_teams">
                  {headerValues.homeTeam} - {headerValues.awayTeam}
                </div>
                <div className="detailed_odds_info_match_date">
                  {clock.toLocaleDateString("ro-RO", {
                    weekday: "short",
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="detailed_odds_filters">
          <div
            className={resolveClassName(0)}
            onClick={() => {
              toggleActiveArr(0);
              setFilterName("TOATE");
            }}
          >
            TOATE
          </div>
          {consumer.allOddsForEvent[0].map((elem, key) => {
            if (key > 0) {
              return (
                <div
                  className={resolveClassName(key)}
                  key={key}
                  onClick={() => {
                    toggleActiveArr(key);
                    setFilterName(elem.betName);
                  }}
                >
                  {elem.betName}
                </div>
              );
            }
          })}
        </div>
        <div className="detailed_slider">
          <div className="slider_value_1">{slider[0]}</div>
          <Slider
            value={slider}
            valueLabelDisplay="auto"
            onChange={onSliderChange}
            step={0.01}
            min={1.01}
            max={20}
          />
          <div className="slider_value_2">{slider[1]}</div>
        </div>
        {oddContent && (
          <div className="detailed_odds_odds">
            {oddContent.map((elem, key) => {
              return (
                <div className="markets_wraper">
                  <div className="market_wrapper_header">{elem.betName}</div>
                  <div className="market_wrapper_wrap">
                    {elem.odds.map((el, i) => {
                      if (el.value >= slider[0] && el.value <= slider[1]) {
                        return (
                          <div
                            className={resolveOddClassName(
                              consumer.spStake[0],
                              headerValues.matchId,
                              el.id
                            )}
                            onClick={() => onClickOdd(el.value, el.id)}
                          >
                            <div className="market_wrap_wrap_symb">
                              {el.symbol}
                            </div>
                            <div className="market_wrap_wrap_value">
                              {el.value > 1 ? el.value : "-"}
                            </div>
                          </div>
                        );
                      }
                    })}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    )
  );
};

export default DetailedOdds;
