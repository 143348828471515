import React from "react";
import { convertUtcToSingleRowTime } from "../../Helpers/Helpers";

const ResultsContentSingleItem = (props) => {
  return (
    <>
      {" "}
      <div className="results_content_single_item_root">
        <div className="results_single_item_date">
          {convertUtcToSingleRowTime(props.elem.eventDate, "ro")}
        </div>
        <div className="results_single_item_code">{props.elem.eventNo}</div>
        <div className="results_single_item_sport">
          <div className="results_single_item_sport_league">
            {props.elem.leagueName}
          </div>
        </div>
        <div className="results_single_item_event">{props.elem.eventName}</div>
        <div className="results_single_item_odds">
          <div className="results_single_item_odds_def">
            {props.elem.colNames.split(",").map((el, i) => {
              return <div key={i}>{el}</div>;
            })}
          </div>
          <div className="results_single_item_odds_values">
            {props.elem.scores.split(",").map((el, i) => {
              return <div key={i}>{el}</div>;
            })}
          </div>
        </div>
      </div>
      <div className="results_content_mobile_row">
        <div className="results_mobile_row1">
          <div className="results_mobile_d1">
            {convertUtcToSingleRowTime(props.elem.eventDate, "ro")}
          </div>
          <div className="results_mobile_d2">{props.elem.leagueName}</div>
          <div className="results_mobile_d3">
            {props.elem.colNames.split(",").map((el, i) => {
              return <div key={i}>{el}</div>;
            })}
          </div>
        </div>
        <div className="results_mobile_row2">
          <div className="results_mobile_d1">{props.elem.eventNo}</div>
          <div className="results_mobile_d2 results_d2_stuff">
            {props.elem.eventName}
          </div>
          <div className="results_mobile_d3 results_mobile_green">
            {props.elem.scores.split(",").map((el, i) => {
              return <div key={i}>{el}</div>;
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultsContentSingleItem;
